import {sendEmail} from "../apiCalls/api/Email";
import {EMAIL_NEW_ORDER} from "../pages/Order/constants";
export const emailComandaTrimisaSpreHnp = (user, orderId) => {
    const url = window.location.origin + "/orderDetails/"+orderId
    const body = {
        type:"new-order",
        subject: 'Confirmare înregistrare comandă',
        orderNumber: `<a href="${url}"> ${orderId} </a>` ,
        email_to: EMAIL_NEW_ORDER,
        customerName: user.userDetails.displayName,
    };
    sendEmail(body)
        .then(function (response) {
            console.log('SUCCESS!', response.status, response.message);
        }, function (error) {
            console.log('FAILED...', error);
        });
}


export const emailComandaNoua = (user,orderId) => {
    const url = window.location.origin + "/orderDetails/"+orderId
    const body = {
        type:"new-order-hnp",
        subject: 'Comanda noua',
        orderNumber: `<a href="${url }"> ${orderId} </a>` ,
        email_to: user.userDetails.email,
        customerName: user.userDetails.displayName,
    };

    sendEmail(body)
        .then(function (response) {
            console.log('SUCCESS!', response.status, response.message);
        }, function (error) {
            console.log('FAILED...', error);
        });
}