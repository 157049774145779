export const ORDER_STEPS = {
    send: {
        description: "Transmisa spre HNP",
        id: 1
    },
    analyze: {
        description: "In analiza",
        id: 2
    },
    process: {
        description: "Confirmata, trimisa catre procesare",
        id: 3
    },
    done: {
        description: "Comanda Livrata",
        id: 4
    },
}

export const orderLogs = (user) => {
    return {
        send: {
            description: "Comanda a fost trimisa catre HNP",
            user: user,
            date: new Date()
        },
        receive: {
            description: "Comanda a fost preluata in analiza de catre un angajat HNP",
            user: user,
            date: new Date()
        },
        process: {
            description: "Comanda a fost trimisa catre procesare",
            user: user,
            date: new Date()
        },
        end: {
            description: "Comanda a fost finalizata si trimisa catre curier",
            user: user,
            date: new Date()
        },
        customLog: (logObjectDescription,data) => {
            return {
                description: logObjectDescription,
                data: data,
                user: user,
                date: new Date()
            }
        }
    }
}

export const SHIPING_COST = 25;
export const SHIPING_COST_FREE = 200;

export const EMAIL_NEW_ORDER = "comenzi@hnp.ro";