import React, {useEffect, useState} from "react";
import Page from '../../../components/Page';
import {Card, Container, Grid, Stack, Typography} from "@mui/material";
import "./css/index.css";
import {saveBlog, updateBlog} from "../../../FireBase/BlogAction";
import {createDocument, getNewsById, updateDocument} from "../../../apiCalls/api/news"
import Notiflix, {Confirm} from "notiflix";
import {useNavigate, useParams} from "react-router-dom";
import {faker} from "@faker-js/faker";
import {useAuth} from "../../../Auth";
import Form from "./form";
import {getDocumentById} from "../../../FireBase/actions";

export default function NewPost() {

    const navigate = useNavigate();
    const {currentUser} = useAuth();
    let {id} = useParams();
    const [product, setProduct] = useState();
    useEffect(async () => {
        id = id.split('-')[0];
        const response = await getNewsById(id);
        setProduct(response.data);
        //setProduct(productData);

    }, [])

    function createData(data) {
        return {
            document: {
                cover: data.cardImage,
                title: data.title,
                content: data.content,
                type: data.type,
                createdAt: new Date(),
                view: 0,
                comment: 0,
                share: 0,
                favorite: 0,
                active: data.active,
                author: {
                    name: faker.name.findName(),
                    avatarUrl: `/static/mock-images/avatars/avatar_${1 + 1}.jpg`
                }
            }
        }
    }

    async function saveData(data) {
        const blogDocument = createData(data);
        Notiflix.Loading.init();
        if (id) {
            //await updateBlog(id.split('-')[0],blogDocument);
            await updateDocument(id.split('-')[0], blogDocument);
        } else {
            //await saveBlog(blogDocument);
            await createDocument(blogDocument);
        }

        Notiflix.Notify.success("Postarea a fost aduagata cu succes")
        Notiflix.Loading.remove()
        navigate('/noutati')
    }

    const onSubmit = async (data) => {
        Confirm.show(
            'Confirmati',
            'Sunteti de acrod sa savati postarea?',
            'Da',
            'Nu',
            () => {
                saveData(data);
            },
            () => {
                return false
            },
        );

    }

    return (
        <Page title="HNP: Noutati | Adaugare">
            <Container>
                <Stack direction="row"
                       alignItems="center"
                       justifyContent="space-between"
                       mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Postare noua
                    </Typography>

                </Stack>
                <Card
                    style={{minHeight: 340}}
                >
                    <Grid>
                        <Form
                            onSubmit={onSubmit}
                            data={product}
                        />

                    </Grid>

                </Card>
            </Container>
        </Page>
    );
}