import * as Yup from 'yup';
import {Form, FormikProvider, useFormik} from 'formik';
// material
import {Box, Stack, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
//functions
import {savePartner} from "../../../FireBase/actions";
import {printErrorMessage, printSuccessMessage} from "../../../apiCalls/apiMessage";

import {useAuth} from "../../../Auth";
import MyAutoComplete from "../../../components/MyAutoComplete";
import {useEffect, useState} from "react";
import {Notify} from "notiflix";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import {unset} from "lodash";
// ----------------------------------------------------------------------

export default function RegisterForm(props) {
    const {setLoadData, loadData, isOpen, setOpenModal, discountGroupOptions} = props;
    const RegisterSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Numele este obligatoriu'),
        reprezentantLegal: Yup.string().min(6, 'Too Short!').max(50, 'Too Long!').required('Prenumele este obligatoriu'),
        consultantName: Yup.string().min(6, 'Too Short!').max(50, 'Too Long!').required('Prenumele este obligatoriu'),
        consultantEmail: Yup.string().email('Email must be a valid email address').required('Email este obligatoiru'),
        email: Yup.string().email('Email must be a valid email address').required('Email este obligatoiru'),
        phone: Yup.string().required('Telefonul este obligatoiru').min(10, 'Trebuie sa fie de minim 10 caracatere.'),
        discountSelected: Yup.object().nullable(),
        discountValue: Yup.number().when('discountSelected', {
            is: (value) => value !== null && typeof value === 'object',
            then: Yup.number().required('Valoarea discountului trebuie sa fie introdusa daca ai sectat o categorie de dicount').min(1, 'discount-ul trebuie sa fie numar mai mare ca 1'),
            otherwise: Yup.number()
        })
    });
    const {currentUser} = useAuth();
    const [options, setOptions] = useState(discountGroupOptions);

    const formik = useFormik({
        initialValues: {
            name: '',
            reprezentantLegal: '',
            email: '',
            discountGroup: [],
            discountSelected: null,
            phone: '',
            consultantEmail: '',
            consultantName: '',
            discountValue: ''
        },
        validationSchema: RegisterSchema,
        onSubmit: (formData) => {
            save(formData)
        },
    });

    const save = (formData) => {
        unset(formData, 'discountSelected');
        unset(formData, 'discountValue');
        console.log(errors)
        const partner = {
            ...formData,
            ...{
                status: true,
                addBy: currentUser.email,
                dateAdd: new Date()
            }
        }
        // todo: check if exist
        savePartner(partner).then(() => {
            printSuccessMessage("Partnerul a fost adaugat cu succes")
            setOpenModal(!isOpen);
            setLoadData(loadData + 1);
        }).catch((error) => {
            printErrorMessage(error);
        });
    }
    const {errors, touched, handleSubmit, getFieldProps} = formik;
    console.log(errors);
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Detalii partener</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <Stack spacing={1}>
                                <TextField
                                    fullWidth
                                    label="Nume client"
                                    {...getFieldProps('name')}
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />

                                <TextField
                                    fullWidth
                                    label="Reprezentant legal"
                                    {...getFieldProps('reprezentantLegal')}
                                    error={Boolean(touched.reprezentantLegal && errors.reprezentantLegal)}
                                    helperText={touched.reprezentantLegal && errors.reprezentantLegal}
                                />
                                <TextField
                                    fullWidth
                                    autoComplete="username"
                                    type="email"
                                    label="Email address"
                                    {...getFieldProps('email')}
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Telefon"
                                        {...getFieldProps('phone')}
                                        error={Boolean(touched.phone && errors.phone)}
                                        helperText={touched.phone && errors.phone}
                                    />
                                </Stack>
                                <TextField
                                    fullWidth
                                    autoComplete="username"
                                    type="consultantEmail"
                                    label="Consultant email"
                                    {...getFieldProps('consultantEmail')}
                                    error={Boolean(touched.consultantEmail && errors.consultantEmail)}
                                    helperText={touched.consultantEmail && errors.consultantEmail}
                                />
                                <TextField
                                    fullWidth
                                    label="Nume consultant"
                                    {...getFieldProps('consultantName')}
                                    error={Boolean(touched.consultantName && errors.consultantName)}
                                    helperText={touched.consultantName && errors.consultantName}
                                />
                            </Stack>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Adauga categorii discount</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <Stack spacing={1}>

                                <MyAutoComplete
                                    id="discountSelected"
                                    name="discountSelected"
                                    label="Alege discount group-ul pentru care vrei sa adaugi valoarea"
                                    options={options}
                                    getOptionLabel={(option) => option.key}
                                    formik={formik}
                                />
                                <TextField
                                    fullWidth
                                    id="discountValue"
                                    name="discountValue"
                                    label="Adauga discount pentru grup-ul ales"
                                    {...getFieldProps('discountValue')}
                                    error={Boolean(touched.discountValue && errors.discountValue)}
                                    helperText={touched.discountValue && errors.discountValue}
                                />
                                <LoadingButton
                                    fullWidth
                                    size="small"
                                    variant="text"
                                    color={'secondary'}
                                    onClick={() => {

                                        if (!formik.values.discountValue) {
                                            Notify.failure('Introdu valoarea discountului',
                                                {
                                                    position: 'center-bottom',
                                                });
                                            formik.submitForm()
                                            return;
                                        }

                                        if (!formik.values.discountSelected.key) {
                                            Notify.failure('Selecteaza un discount group',
                                                {
                                                    position: 'center-bottom',
                                                });
                                            return;
                                        }
                                        const cloneGroupSelected = JSON.parse(JSON.stringify(formik.values.discountGroup));
                                        const existValue = formik.values
                                                            .discountGroup
                                                                .find((item) => item.key === formik.values.discountSelected.key);
                                        if (existValue) {
                                            Notify.failure('Discount-ul este deja adaugat',
                                                {
                                                    position: 'center-bottom',
                                                });
                                            return;
                                        }
                                        cloneGroupSelected.push({
                                            id: formik.values.discountGroup.length + 1,
                                            key: formik.values.discountSelected.key,
                                            value: +formik.values.discountValue
                                        });
                                        formik.setFieldValue('discountGroup', cloneGroupSelected);
                                        let newOptions = options.filter((item) => item.key !== formik.values.discountSelected.key);

                                        setOptions(newOptions);
                                        Notify.success('Discount-ul a fost adaugat cu succes', {
                                            position: 'center-bottom',
                                        });
                                    }
                                    }
                                >
                                    SALVEAZA DISCOUNT
                                </LoadingButton>
                                <Box>
                                    {formik.values.discountGroup.map((group) => (
                                        <Chip
                                            key={group.id}
                                            label={`[${group.key}] - ${group.value}%`}
                                            onDelete={() => {
                                                const updatedGroups = formik.values.discountGroup.filter(item => item.id !== group.id);
                                                setOptions([...options, ...formik.values.discountGroup.filter(item => item.id === group.id)]);
                                                formik.setFieldValue('discountGroup', updatedGroups);
                                            }}
                                            deleteIcon={<DeleteIcon/>}
                                            variant="outlined"
                                            sx={{m: 0.2}}
                                        />
                                    ))}
                                </Box>
                            </Stack>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Stack spacing={3}>
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        Salveaza partenerul
                    </LoadingButton>
                </Stack>


            </Form>
        </FormikProvider>
    )
        ;
}
