import {useState} from "react";
import {getProductsDetails} from "../../../apiCalls/api/Products";
import {getProductPrice} from "../../catalog/functions/actions";
import {useAuth} from "../../../Auth";
import {calculatePriceWithDiscount} from "../../../utils/utils";
import {saveLogProductView} from "../../../FireBase/actions";

export default function useUserDetails() {
    const [product, setDetails] = useState();
    const {currentUser} = useAuth();
    const setProductDetails = async (id) => {
        const details = await getProductsDetails(id)
        if (currentUser?.login === true && currentUser?.userDetails?.status === true) {
            details.data._source.PRICE = await getProductPrice(details.data._source.SUPPLIER_ALT_AID);
            details.data._source.PRICE_DISCOUNT =  await calculatePriceWithDiscount(currentUser?.companyDetails?.discountGroup,details.data);
        }
       await saveLogProductView(id, details?.data?._source?.DESCRIPTION_SHORT);
        setDetails(details.data);
    };

    return [product, setProductDetails];
}

