// material
import {Container, Grid} from '@mui/material';
// components
import Page from '../../components/Page';
import OrderListView from "../Order/OrderListView";
import {
    AppBugReports,
    AppConversionRates,
    AppCurrentSubject,
    AppCurrentVisits,
    AppItemOrders,
    AppNewsUpdate,
    AppNewUsers,
    AppOrderTimeline,
    AppTasks,
    AppTrafficBySite,
    AppWebsiteVisits,
    AppWeeklySales
} from "../../sections/@dashboard/app";
import {getTotalUsers} from "../../FireBase/actions";
import {useEffect, useState} from "react";
import Notiflix from "notiflix";
// ----------------------------------------------------------------------

export default function DashboardApp() {
    const [totalStatistics, setTotalStatistics] = useState(
        {
            users: 0,
            orders: 0,
            partners: 0,
            blog: 0
        }
    );
    useEffect( () => {
        /*   Notiflix.Loading.pulse('Se incarca...');
          getTotalUsers().then(data => {
               Notiflix.Loading.remove();
               setTotalStatistics(data);
           });*/

    }, []);
    return (
        <Page title="HNP Romania | Administrare">
            <Container maxWidth="xl">
                <Grid container spacing={3}>
{/*
                    <Grid item xs={12} sm={6} md={3}>
                        <AppWeeklySales
                            total={totalStatistics.users}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppNewUsers
                            total={totalStatistics.blog}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppItemOrders
                            total={totalStatistics.orders}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppBugReports
                            total={totalStatistics.partners}
                        />
                    </Grid>
*/}
                    <Grid item sx={{m: 0}} xs={12} sm={12} md={12}>
                        <OrderListView sx={{mt: 2}}
                                       title="Comenzile  HNP"
                        />
                    </Grid>
{/*


                    <Grid item xs={12} md={6} lg={8}>
                        <AppWebsiteVisits/>
                    </Grid>


                    <Grid item xs={12} md={6} lg={4}>
                        <AppCurrentVisits/>
                    </Grid>

                    <Grid item xs={12} md={6} lg={8}>
                        <AppConversionRates/>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <AppCurrentSubject/>
                    </Grid>

                    <Grid item xs={12} md={6} lg={8}>
                        <AppNewsUpdate/>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <AppOrderTimeline/>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <AppTrafficBySite/>
                    </Grid>

                    <Grid item xs={12} md={6} lg={8}>
                        <AppTasks/>
                    </Grid>
*/}
                </Grid>
            </Container>
        </Page>
    );
}
