import {Box, CardActionArea} from "@mui/material";
import {BASE_URL_IMAGES} from "../../../utils/utils";
import Typography from "@mui/material/Typography";
import * as React from "react";

export const CategoryCard = (props) => {
    const {product,onSelectItem} = props
    const defaultImage ='/static/hnp-images/drillCNC.jpg';
    return (
        <CardActionArea onClick={()=>onSelectItem(product)} sx ={{borderRadius:3}}>
            <Box sx={{minHeight: 250, position: 'relative', overflow: 'hidden', borderRadius: 3}}>
                <Box
                    src={BASE_URL_IMAGES + product.img}
                    alt={product.group_name}
                    sx={{
                        width: '100%',
                        height: '100%',
                        minHeight: 200,
                        backgroundImage: `url(${BASE_URL_IMAGES + product.img}), url(${defaultImage})`,
                        objectPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: 'rgb(74 77 80)',
                        color: 'white',
                        padding: 1
                    }}
                >
                    <Typography gutterBottom variant="h5" component="div">
                        {product.group_name}
                    </Typography>
               {/*     <Typography variant="body2" color="white">
                        {product.doc_count}
                    </Typography>*/}
                </Box>
            </Box>
        </CardActionArea>

    );
};