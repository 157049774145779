import Modal from "../../../components/Modal";
import AddCompany from "../form/AddCompany";

export default function AddCompanyModal(props){
    const {openModal,setOpenModal,editRow,setLoadData,loadData} = props;

    return (
        <Modal
            isOpen={openModal}
            setOpenModal={setOpenModal}
            title={"Asocieaza companie pentru: " + editRow.lastName}
            setLoadData={setLoadData}
            loadData={loadData}
        >
            <AddCompany
                setLoadData={setLoadData}
                loadData={loadData}
                isOpen={openModal}
                setOpenModal={setOpenModal}
                editRow={editRow}

            />
        </Modal>
    );
}