import {ORDER_STEPS, orderLogs} from "./constants"
import {addShippingCost, getClientFinalPrice, getTotal} from "../../utils/utils";
import {saveOrder} from "../../FireBase/actions";
import {emailComandaNoua, emailComandaTrimisaSpreHnp} from "../../emailjs/emailjs";
import {getDocumentById} from "../../FireBase/actions";
import {doc, getDoc, writeBatch,arrayUnion} from "firebase/firestore";
import {db} from "../../FireBase/base";
import Notiflix from "notiflix";

export const saveOrderAction = (cart, address, user, clearCart) => {
    const hasMissingPrice = cart.some(item => !item._source || item._source.PRICE === undefined);
    if (hasMissingPrice) {
        Notiflix.Notify.failure('Produsele din cos nu au pretul setat, contactati HNP');
        return false;
    }
    user.userDiscount = 5
    const subTotal = getTotal(cart)
    let totalCost = getClientFinalPrice(cart,  user?.companyDetails?.discountGroup)
    const totalDiscount = (subTotal - totalCost).toFixed(2);
    totalCost = addShippingCost(totalCost);
    const order = {
        subTotal: parseFloat(subTotal),
        totalCost: parseFloat(totalCost),
        totalDiscount: parseFloat(totalDiscount),
        userDiscount: user?.companyDetails?.discountGroup,
        orderDetails: cart,
        assigned: null,
        address: address[0],
        statusId: ORDER_STEPS.send.id,
        statusDescription: ORDER_STEPS.send.description,
        dateAdd: new Date(),
        dateUpdate: null,
        logs:[orderLogs(user.userDetails).send],
        customer: {
            uid: user.uid,
            ...user.userDetails
        }
    }

    saveOrder(order).then(((docRef) => {
        emailComandaTrimisaSpreHnp(user, docRef.id)
        emailComandaNoua(user,docRef.id)
        clearCart()
    })).catch((error) => {
        console.error("Error adding document: ", error);
    })


}

export const updateOrderStatus = async (orderId, status, user,newLogEntry) => {
    try {
        const batch = writeBatch(db);
        const ref = doc(db, 'orders',orderId);
        const refDoc = await getDoc(ref);
        const document = refDoc.data()
        const oldLogs = JSON.parse(JSON.stringify(document.logs )) || [];
        newLogEntry.id = oldLogs.length + 1;
        // Convert newLogEntry to a plain JavaScript object

        const plainLogEntry = {
            id: newLogEntry.id,
            ...newLogEntry // Spread other properties of newLogEntry
        };
        console.log(plainLogEntry)
        const order = {
            statusId: status.id,
            statusDescription: status.description,
            assigned: user.uid,
            logs: arrayUnion(plainLogEntry),
            assignedName: user.userDetails.displayName,
            dateUpdate: new Date()
        };
        batch.update(ref, order);
        await batch.commit();


    } catch (error) {
        console.error("Error updating document: ", error);
    }
}

export const updateOrder = async ( order) => {
    try {
        const batch = writeBatch(db);
        const ref = doc(db, 'orders',order.id);
        batch.update(ref, order);
        await batch.commit();
    } catch (error) {
        console.error("Error updating document: ", error);
    }
}