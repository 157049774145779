import {filter} from "lodash";
import {SHIPING_COST_FREE,SHIPING_COST} from "../pages/Order/constants";
export const sysDate = () => {
    let today = new Date();
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    return date + ' ' + time;
}
export const parseDate = (value) => {
    const fireBaseTime = new Date(
        value.seconds * 1000 + value.nanoseconds / 1000000,
    );
    const date = fireBaseTime.toDateString();
    const atTime = fireBaseTime.toLocaleTimeString('ro-RO');
    return date + " " + atTime
}
export const getTotal = (cart) => {
    let total = 0;
    cart.forEach(item => {
        if (item._source.PRICE) {
            total += item.quantity * item._source.PRICE;
        }
    })
    return total.toFixed(2);
}

export function calculatePriceWithDiscount(discountGroup,product) {
    let discountValue =null
    if (discountGroup) {
        const discount = discountGroup.find(item => item.key === product._source['UDX.DISCOUNTGROUP']);
        discountValue = discount ? discount.value : 0;
    }
    return applayDiscount(product._source.PRICE, discountValue);
}

export function applayDiscount(price, discount) {
    typeof discount === 'undefined' && (discount = 0);
    typeof price === 'undefined' && (price = 0);
    typeof discount === 'string' && (discount = parseInt(discount));
    return price - (price * discount / 100);
}
export const getClientFinalPrice = (cart, discountGroup) => {
    let total = 0;
    cart.forEach(item => {
        total += calculatePriceWithDiscount(discountGroup, item) * item.quantity;
    })
    return total.toFixed(2);
}
export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
export const addShippingCost = (totalCost) =>{
    if (typeof totalCost === 'string') {
        totalCost = parseInt(totalCost, 10);
    }
    if (totalCost >= SHIPING_COST_FREE) {
        return totalCost;
    }
    return totalCost + SHIPING_COST;
}
export const calculateShippingCost = (totalCost) =>{
    if (totalCost >= SHIPING_COST_FREE) {
        return 0;
    }
    return SHIPING_COST;
}
export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
export function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.displayName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}
export const BASE_URL_IMAGES = 'https://hnp.ro'
export const CURRANCY = "$";
