// material
import {styled} from '@mui/material/styles';
// ----------------------------------------------------------------------
import {useAuth} from "../../Auth";
import UserNotActive from "../../pages/UserNotActive";
import NavBar from "./NavBar/NavBar";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
});

const MainStyle = styled('div')(({theme}) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));
// ----------------------------------------------------------------------

export default function DashboardLayout(props) {

    const navigate = useNavigate();
    const {currentUser} = useAuth();
    useEffect(() => {
        if(currentUser.login === false){
            navigate('/login');
        }
    }, []);

    const Main = (props) => {
        return (<RootStyle>
            <NavBar/>
            <MainStyle>
                {props.children}
            </MainStyle>
        </RootStyle>)
    }

    return (
        <Main>
            {props.admin === true &&  currentUser.userDetails.role === "ROLE_USER" ? navigate('/404') : ""}
            {currentUser.emailVerified === true && currentUser.userDetails.status === true ? props.children : <UserNotActive
                currentUser={currentUser}
            />}
        </Main>)
}
