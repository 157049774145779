import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
// material
import {Container, Grid} from '@mui/material';
// components
import Page from '../../components/Page';
import {ProductList,} from '../../sections/@dashboard/products';
// firebaseActions
import {
    getFavoriteProducts,
    getFuzzySearchProducts,
} from "../../apiCalls/api/Products"
import Pagination from "../../components/Pagination";
import SearchInput from "../../components/SearchInput";
import NotificationInfo from "../../components/NotificationInfo";
import {useAuth} from "../../Auth";
import Footer from "../../layouts/dashboard/Footer/Footer";
import CatalogPage from "./CatalogPage";
// functions
import {addProductPrice} from "./functions/actions";
import Notiflix from "notiflix";

const INDEX_PRODUCTS = 'elk-excel-index-new';
const ROWS_PER_PAGE = 10;
export default function SearchPage(props) {
    const {handleAddProductToExistingOrder,addToOrder} = props;
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [from, setFromProduct] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [searchText, setSearchText] = useState(null);
    const [reloadData, setReloadData] = useState(false);
    const [showSearch, setShow] = useState(false);
    const firstRender = useRef(true);
    const [favoriteProducts, setFavoriteProducts] = useState([]);
    const [hideFavorite, setHideFavorite] = useState(false);
    const {currentUser} = useAuth();


    useEffect(async () => {
        if (firstRender.current) {
            Notiflix.Loading.pulse('Se incarca...');
            const result = await getFavoriteProducts()
            const favoriteProductsWithPrice = await addProductPrice(result, currentUser)
            setFavoriteProducts(favoriteProductsWithPrice);
            firstRender.current = false;
            Notiflix.Loading.remove();
            return
        }
        await getProducts(INDEX_PRODUCTS, from, searchText)

    }, [reloadData]);
    const getProducts = async (index, fromTo, search) => {
        const result = await getFuzzySearchProducts(index, fromTo, ROWS_PER_PAGE, search);
        const products = await addProductPrice(result, currentUser)
        setProducts(products);
        setTotalRows(result.data.hits.total.value);
    }

    return (
        <Page title="HNP Romania |  Produse">
            <Container maxWidth="xl">
                <Grid container sx={{flexGrow: 1}} spacing={2}>
                    <Grid item xs={12}>
                        <NotificationInfo showDefaultMessage={true}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <SearchInput
                            onSearch={(value) => {
                                setSearchText(value)
                                setPage(1);
                                setReloadData(!reloadData);
                                if (showSearch === false) {
                                    setShow(true);
                                }
                                if (value === null) {
                                    setShow(false);
                                    setHideFavorite(false);
                                } else {
                                    setHideFavorite(true);
                                }

                            }}
                        />
                    </Grid>
                    {showSearch === false && !addToOrder ?
                        <>
                            <Grid item xs={12} md={12}>
                                <CatalogPage
                                    setHideFavorite={setHideFavorite}
                                />
                            </Grid>
                        </>
                        : ""}
                    { !hideFavorite ?
                        <>
                            <Grid item xs={12} md={12}>
                                <ProductList products={favoriteProducts}
                                                     handleAddProductToExistingOrder={handleAddProductToExistingOrder}
                                                     addToOrder={addToOrder}
                                />
                            </Grid>
                        </>
                        : ""}
                    {showSearch === true ?
                        <Grid item xs={12} md={12}>
                            <ProductList
                                products={products}
                                handleAddProductToExistingOrder={handleAddProductToExistingOrder}
                                addToOrder={addToOrder}

                            />
                            <Pagination
                                totalRows={totalRows}
                                page={page}
                                totalRowsPerPage={ROWS_PER_PAGE}
                                handleChange={(from, newPage) => {
                                    setPage(newPage);
                                    setFromProduct(from);
                                    setReloadData(!reloadData);
                                }}
                            />
                        </Grid> : ""}
                    <Grid item xs={12} md={12}>
                        { !addToOrder ? <Footer/> :""}
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
