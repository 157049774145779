import React from "react";
import './css/index.css'

export default function MyUpload(props) {

    const {onChange, value} = props;
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    return (
        <>
            <div style={{display:"flex"}}>

                <div>
                    <input
                        className='custom-file-upload'
                        type="file"
                        name="file"
                        onChange={(data) => {
                            toBase64(data.target.files[0]).then((data) => {
                                onChange(data);
                            });
                        }}
                    />
                </div>
                <div>
                    {value && (
                        <img src={value} alt="Uploaded" style={{maxWidth: '10%', marginLeft: '10px'}}/>
                    )}

                </div>

                {props.error === true ?
                    <p className="css-16d5wub-MuiFormHelperText-root"
                       style={{color: "red"}}>
                        {props.errorMessage}
                    </p> : ""}
            </div>
        </>
    );
}