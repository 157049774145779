import {Button, Typography} from "@mui/material";
import Iconify from "../../../components/Iconify";
import * as React from "react";
import {useAuth} from "../../../Auth";
import {fCurrency} from "../../../utils/formatNumber";

export const ProductButtons = (props) => {
    const {onAddToCart,data} = props;
    const {currentUser} = useAuth();
    const priceSale = data._source.PRICE;
    const priceWithDiscount = data._source.PRICE_DISCOUNT;
    return (
        <>
            <Button
                sx={{
                    width: '80%',
                    height: 50,
                }}
                variant="contained"
                disabled={(!(currentUser.emailVerified === true && currentUser.userDetails.status === true))}
                onClick={onAddToCart}
                startIcon={<Iconify icon="material-symbols:add-shopping-cart"/>}
            >ADAUGA IN COS &nbsp;

                {currentUser.login && currentUser?.userDetails?.status &&
                    <>
                        {priceSale !== priceWithDiscount && priceSale ? <Typography
                            component="span"
                            variant="body1"
                            sx={{
                                color: 'text.disabled', textDecoration: 'line-through'
                            }}
                        >
                            {fCurrency(priceSale)}
                        </Typography> : ""}
                        <Typography
                            component="span"
                            variant="body1"
                        >
                            &nbsp;
                            {fCurrency(priceSale !== priceWithDiscount ? priceWithDiscount : priceSale)}
                        </Typography>
                    </>
                }

            </Button>
                {/*            <Button
                sx={{
                    width: '80%',
                    height: 50,
                    mt: 2,
                }
                }
                variant="outlined"
                onClick={onAddToWishlist}
                startIcon={<Iconify icon="icon-park-outline:love-and-help"/>}
            >Adauga la favorite</Button>*/}
        </>
    );
}