import Page from "../../components/Page";
import {Box, Button, Container, Grid, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {getDocumentById} from "../../FireBase/actions";
import CartContent from "./components/CartContent";
import ShipingCard from "./components/ShipingCard";
import UserOrderDetails from "./components/UserOrderDetails";
import Iconify from "../../components/Iconify";
import {useAuth} from "../../Auth";
import {updateOrder, updateOrderStatus} from "./action";
import {ORDER_STEPS, orderLogs} from "./constants";
import {addToCartRedux} from "../../store/cart/cartAction";
import Notiflix from "notiflix";
import {addShippingCost, getClientFinalPrice, getTotal} from "../../utils/utils";

const INITIAL_ORDER_STATE = 1

const IN_ANALYZE = 2

const IN_PROCESS = 3

export default function OrderDetails() {

    let {orderId} = useParams();
    const [cart, setCart] = useState([]);
    const [order, setOrder] = useState();
    const [statusOrder, setStatusOrder] = useState(INITIAL_ORDER_STATE);
    const [company, setCompany] = useState();
    const userAssignOrder = useRef("")
    const [orderLogsLocal, setOrderLogs] = useState([]);
    const {currentUser} = useAuth();


    console.log("order", order);
    //console.log("status", statusOrder)

    useEffect(async () => {
        await loadOrder();
    }, [])
    async function loadOrder() {
        const dbOrder = await getDocumentById('orders', orderId);
        const company = await getDocumentById('partners', dbOrder.customer.companyId);
        setCompany(company)
        setCart(dbOrder.orderDetails);
        setOrder(dbOrder);
        userAssignOrder.current = dbOrder.assignedName
        setStatusOrder(dbOrder.statusId)
    }
    const handleAssignOrder = async () => {
        console.log("Handle assign order");
        Notiflix.Loading.pulse('Se preia comanda');
        updateOrderStatus(order.id, ORDER_STEPS.analyze, currentUser, orderLogs(currentUser.userDetails).receive)
            .then(() => setStatusOrder(ORDER_STEPS.analyze.id))
            .catch((error) => {
                console.log("Eroare preluare comanda", error);
                Notiflix.Notify.failure('A aparut o eroare la preluarea comenzii');
            })
            .finally(() => {
                Notiflix.Loading.remove()
            });

    }
    const handleAddToCart = (product) => {
        const data = {
            product_id : product._id,
            quantity : 1,
        }
        const updatedOrderDetails = order.orderDetails.map(item => {
            if (item._id === product._id) {
                setOrderLogs([...orderLogsLocal, orderLogs(currentUser.userDetails).customLog("S-a modificat cantiatatea pentru produsul + 1", data)])
                return {...item, quantity: item.quantity + 1};
            }
            return item;
        });
        setOrder({...order, orderDetails: updatedOrderDetails});
        setCart(updatedOrderDetails)
    }
    const handleRemoveQuantity = (product) => {
        const data = {
            product_id : product._id,
            quantity : 1,
        }
        const updatedOrderDetails = order.orderDetails.map(item => {
            if (item._id === product._id) {
                setOrderLogs([...orderLogsLocal, orderLogs(currentUser.userDetails).customLog("S-a modificat cantiatatea pentru produsul -1 ", data)])
                return {...item, quantity: item.quantity - 1};
            }
            return item;
        });

        const updatedOrderDetailsFiltered = updatedOrderDetails.filter(item => item.quantity > 0);
        if (updatedOrderDetailsFiltered.length < updatedOrderDetails.length) {
            setOrderLogs([...orderLogsLocal, orderLogs(currentUser.userDetails).customLog("Stergere produs quantity 0  ", product)])

        }

        setOrder({...order, orderDetails: updatedOrderDetailsFiltered});
        setCart(updatedOrderDetailsFiltered)
    }
    const handleRemoveProduct = (product) => {
        const data = {
            product_id : product._id,
            quantity : 1,
        }
        const updatedOrderDetails = order.orderDetails.filter(item => item._id !== product._id);
        setOrder({...order, orderDetails: updatedOrderDetails});
        setCart(updatedOrderDetails);
        setOrderLogs([...orderLogsLocal, orderLogs(currentUser.userDetails).customLog("Stergere produs", data)])
    }
    const handleAddNewItem = (product) => {
        const data = {
            product_id : product._id,
            quantity : 1,
        }
        setOrderLogs([...orderLogsLocal, orderLogs(currentUser.userDetails).customLog("Adaugare produs", data)])
        const newItems = addToCartRedux(product, cart).payload;
        setOrder({...order, orderDetails: newItems});
        setCart(newItems);
    }
    const closeOrder = async () => {
        Notiflix.Loading.pulse('Se finalizeaza comanda');
        const subTotal = getTotal(cart)
        let totalCost = getClientFinalPrice(cart, order?.userDiscount)
        const totalDiscount = (subTotal - totalCost).toFixed(2);
        totalCost = addShippingCost(totalCost);

        const orderUpdated = {
            ...order,
            subTotal: subTotal,
            totalCost: totalCost,
            totalDiscount: totalDiscount,
            orderDetails: cart,
            statusDescription: ORDER_STEPS.process.description,
            logs: [...order.logs, ...orderLogsLocal, ...[orderLogs(currentUser.userDetails).process]],
            statusId: ORDER_STEPS.process.id,
        }
        updateOrder(
            orderUpdated
        ).then(() => {
                setStatusOrder(IN_PROCESS)
                Notiflix.Notify.success('Comanda finalizata cu succes')

            }
        )
            .catch((error) => {
                console.error("Eroare la finalizarea comenzii", error);
                Notiflix.Notify.failure('A aparut o eroare la finalizarea comenzii');
            })
            .finally(() => {
                Notiflix.Loading.remove()
            })
    }


    return (
        <Page title="HNP Romania |  Cosul tau">
            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid item={12}>
                        <Box sx={{display: "flex"}}>
                            <Button
                                variant={statusOrder !== INITIAL_ORDER_STATE ? 'contained' : 'outlined'}
                                color="success"
                                disableRipple
                                disabled={statusOrder !== INITIAL_ORDER_STATE}
                                onClick={handleAssignOrder}
                                endIcon={<Iconify
                                    icon={statusOrder !== INITIAL_ORDER_STATE ? 'eva:checkmark-circle-outline' : 'eva:loader-outline'}/>}
                            >
                                {statusOrder === INITIAL_ORDER_STATE ? 'Preia comanda' : 'Comanda preluata'}
                            </Button>
                            <Typography variant="h6"
                                        sx={{ml: 3, mt: 0.5}}>
                                {statusOrder === 2 ? `- comanda este in lucru la ${userAssignOrder.current}` : ""}
                            </Typography>
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={12}>
                        {company && order ?
                            <UserOrderDetails
                                order={order}
                                company={company}
                            /> : ""}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8}>

                                <CartContent
                                             addProductToOrder ={true}
                                             cart={cart}
                                             viewMode={(IN_ANALYZE !== statusOrder)}
                                             handleAddNewItem={handleAddNewItem}
                                             handleAddToCart={handleAddToCart}
                                             handleRemoveQuantity={handleRemoveQuantity}
                                             handleRemoveProduct={handleRemoveProduct}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <ShipingCard
                                    cart={cart}
                                    discountGroup={order?.userDiscount}/>
                                <Box sx={{mt: 2}}>
                                    {currentUser?.userDetails?.role === "ROLE_ADMIN" ?
                                        <Button
                                            disabled={!(statusOrder === IN_ANALYZE)}
                                            onClick={
                                                () => {
                                                    Notiflix.Confirm.show(
                                                        'Confirmare',
                                                        'Sunteți sigur că doriți să finalizați comanda?',
                                                        'Da',
                                                        'Nu',
                                                        () => {
                                                            closeOrder()
                                                        },
                                                        () => {
                                                            Notiflix.Notify.info('Finalizarea comenzii a fost anulată');
                                                        }
                                                    );
                                                }}
                                            variant={"contained"}

                                        >Finalizeaza Comanda</Button> : ""}
                                </Box>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}