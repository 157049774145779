import PropTypes from 'prop-types';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
// material
import {Box, Button, Card, IconButton, Link, Stack, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
// utils
import Label from '../../../components/Label';
import {BASE_URL_IMAGES, calculatePriceWithDiscount} from "../../../utils/utils";
import { fCurrency} from "../../../utils/formatNumber";
import {useDispatch, useSelector} from "react-redux";
import {addToCartRedux} from "../../../store/cart/cartAction";
import {useAuth} from "../../../Auth";

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
    top: 0, width: '100%', aspectRatio: '3/2', objectFit: 'contain', position: 'relative'
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
    product: PropTypes.object
};

function getCardImage(product) {
    if (product._source.MIMESOURCENORMALFILE2 === null) {
        return product._source.MIMESOURCENORMALFILE1;
    }
    return product._source.MIMESOURCENORMALFILE2;
}

function replaceSpacesWithUnderscores(str) {
    return str.replace(/\s+/g, '_');
}

export default function ShopProductCard({product, id, addToOrder, handleAddProductToExistingOrder}) {
    const DESCRIPTION_SHORT = product._source.DESCRIPTION_SHORT;

    const {currentUser} = useAuth()
    const priceSale = product._source.PRICE;
    const cart = useSelector((state) => state.cart);
    const imagePath = getCardImage(product);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const status = ''


    const priceWithDiscount = calculatePriceWithDiscount(currentUser?.companyDetails?.discountGroup,product);

    return (<Card
        sx={{
            ':hover': {
                boxShadow: 20,
            },
        }}>
        <Box sx={{
            height: 'auto', position: 'relative', m: 1
        }}>
            {status && (<Label
                variant="filled"
                color={(status === 'sale' && 'error') || 'info'}
                sx={{
                    zIndex: 9, top: 16, right: 16, position: 'absolute', textTransform: 'uppercase'
                }}
            >
                {status}
            </Label>)}
            <ProductImgStyle alt={DESCRIPTION_SHORT}
                             src={BASE_URL_IMAGES + imagePath}/>
        </Box>

        <Stack spacing={2} sx={{p: 3}}>
            <Link
                to={'/detaliiProdus/' + replaceSpacesWithUnderscores(DESCRIPTION_SHORT.replace('/', '-')) + '/' + id}
                color="inherit"
                underline="hover"
                target={addToOrder ? "_blank" : "_self"}
                component={RouterLink}>
                <Typography variant="subtitle2" noWrap>
                    {DESCRIPTION_SHORT}
                </Typography>
            </Link>


            {currentUser.login && currentUser.userDetails.status &&
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle1">
                        {priceSale !== priceWithDiscount && priceSale ? <Typography
                            component="span"
                            variant="body1"
                            sx={{
                                color: 'text.disabled', textDecoration: 'line-through'
                            }}
                        >
                            {fCurrency(priceSale)}
                        </Typography> : ""}
                        &nbsp;
                        {fCurrency(priceSale !== priceWithDiscount ? priceWithDiscount : priceSale)}
                    </Typography>
                    <IconButton color="inherit" aria-label="add to shopping cart" disabled={!priceSale}
                                onClick={() => {
                                    if (addToOrder) {
                                        handleAddProductToExistingOrder(product)
                                    } else {
                                        dispatch(addToCartRedux(product, cart))
                                    }
                                }}
                    >
                        <AddShoppingCartIcon/>
                    </IconButton>
                </Stack>}
            {!addToOrder ? <Button
                onClick={() => {
                    navigate('/detaliiProdus/' + replaceSpacesWithUnderscores(DESCRIPTION_SHORT.replace('/', '-')) + '/' + id);
                }}
            >Vezi detalii</Button> : ""}

        </Stack>

    </Card>);
}
