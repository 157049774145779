// AboutPage.js
import React from 'react';
import { motion } from 'framer-motion';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';
import { Container, Grid, Typography,  Card, CardContent, Box } from '@mui/material';
import Footer from "../../layouts/dashboard/Footer/Footer";
const MyTimeline = () => {
    const timelineData = [
        {
            year: '1995',
            description: 'HNP s-a „născut” în 22 decembrie 1995, la inițiativa a trei colegi, la acel moment asistenți universitari la Facultatea de Mecanică a Politehnicii Timișoara. Ideea a pornit de la teza de doctorat a unuia dintre ei, din domeniul „îmbinărilor filetate”.'
        },
        {
            year: '1999',
            description: 'În anul 1999 activitatea firmei s-a relansat, odată cu cooptarea a doi noi acționari, an în care a și început colaborarea cu firma BASS din Germania, producător de tarozi profesionali (scule pentru filetare). Acesta a fost pasul prin care HNP a „intrat” în lumea sculelor pentru prelucrări prin aschiere.',
        },
        {
            year: '2001',
            description: 'In 2001 a început parteneriatul cu firma WALTER, producător de top de scule aschietoare, care a conturat traiectoria ulterioară a HNP',        },
        {
            year: '1995',
            description: 'In 2003 a luat ființă HNP TOOLS, care în acest moment este partener exclusiv WALTER pentru zona de Vest și Sud Vest a României. HNP TECHDEVICES a continuat să-și extindă aria colaborărilor cu parteneri producători premium din Germania, Italia, Suedia, Spania, Elvetia și SUA.'
        },
        {
            year: 'In prezent',
            description: 'In prezent, cu un portofoliu de peste 200 de clienți mulțumiți, ne număram printre cei mai serioși jucători ai pieței de nișă a sculelor aschietoare din România, având o acoperire națională, cu o echipă și un portofoliu de produse și servicii de cea mai înaltă calitate.'
        }
    ];

    return (
        <Container sx={{ py: 2 }}>
            <Timeline position="alternate">
                {timelineData.map((item, index) => (
                    <TimelineItem key={index}>
                        <TimelineOppositeContent>
                            <Typography variant="h6" color="textSecondary">
                                {item.year}
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.3 }}
                            >
                                <Box component={Card} className="bg-box">
                                    <CardContent>
                                        <Typography paragraph>{item.description}</Typography>
                                    </CardContent>
                                </Box>
                            </motion.div>
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </Container>
    );
};
const AboutPage = () => {
    return (
        <>
            <Container  maxWidth="xl" id="about-page">
                <Typography variant="h4" gutterBottom>Despre HNP</Typography>
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <Box className="about-page-block">
                            <Typography paragraph>HNP este o companie din Timișoara, cu o tradiție de peste 20 de ani și cu un colectiv format din absolvenți ai Facultătii de Mecanică care importă și distribuie scule și accesorii profesionale de înaltă performanță pentru industria mecanică prelucrătoare.</Typography>
                            <Typography paragraph>Cu profesionalism și seriozitate, oferim clienților noștri soluții complete, integrate, din domeniul prelucrărilor prin aschiere, manifestând în acelasi timp promptitudine față de nevoile specifice ale partenerilor noștri</Typography>
                            <Typography paragraph>Suntem unici distribuitori pe piața din România ai reputaților producători BASS, EBERLE, SCM, SMICUT, SPECIALINSERT, TECNOSPIRO si parteneri WALTER pentru zona de Vest și Sud Vest a României.</Typography>
                            <Typography paragraph>Beneficiile pe care le oferim clienților nostri sunt:</Typography>
                            <ol style={{ fontSize: '14px', marginLeft:'30px' }}>
                                <li>Oferim soluții tehnice optime și ”scule la tema”, acolo unde situația o impune, prin relația directă cu producatori specializați</li>
                                <li>Oferim consultanță tehnică permanentă, pentru optimizarea procesului de producție</li>
                                <li>Colaborăm cu ingineri cu un înalt grad de pregătire în domeniu</li>
                                <li>Demonstrăm promptitudine și profesionalism în relația cu toți partenerii noștri.</li>
                            </ol>
                            <Typography paragraph>In cadrul grupului HNP activează cele două companii: HNP Techdevices și HNP Tools.</Typography>
                            <Typography paragraph>Compania noastră a întreprins în anul 2011 demersul voluntar de certificare pentru implementarea cu success a Sistemul de Management al Calității conform standardului internațional ISO 9001:2008.</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item xs={6}>
                                <Box className="about-page-img">
                                    <img src="/static/hnp-images/drillCNC.jpg" className="img-responsive" alt="about" style={{ width: '100%' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className="about-page-img">
                                    <img src="/static/hnp-images/choose.jpg" className="img-responsive" alt="about" style={{ width: '100%' }} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Container sx={{ py: 2,mt:5 }}>
                    <Typography variant="h4" align="center" gutterBottom>Istoria HNP</Typography>
                    <Grid container spacing={2}>
                        <MyTimeline/>
                    </Grid>
                </Container>
                <Footer/>
            </Container>
        </>
    );
};

export default AboutPage;