import {Box, Card, Divider, Grid, Typography} from "@mui/material";
import {addShippingCost, calculateShippingCost, getClientFinalPrice, getTotal} from "../../../utils/utils";
import * as React from "react";
import {useRef} from "react";
import {fCurrency} from "../../../utils/formatNumber";
import {SHIPING_COST_FREE} from "../constants";

export default function ShipingCard(props) {
    const {cart,discountGroup} = props;
    const subTotal = getTotal(cart)
    let totalCost = getClientFinalPrice(cart, discountGroup)
    const priceWithoutShipping = totalCost;
    const totalDiscount = (subTotal - totalCost).toFixed(2);
    totalCost = addShippingCost(totalCost);

    return (
        <Card>
            <Grid container spacing={1} sx={{p: 4}} xs={12} md={12}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h6" sx={{mb: 1, display: 'flex'}}>
                        Sumar comanda
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <Typography variant="inherit">Sub Total</Typography>
                        <Typography variant="inherit"> {fCurrency(subTotal)} </Typography>

                    </Box>
                </Grid>
                <Grid item xs={12} md={12}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <Typography variant="inherit">Reducere partener</Typography>
                        <Typography variant="inherit"> {fCurrency(totalDiscount)} </Typography>

                    </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        mb: 2
                    }}>
                        <Typography variant="inherit">Cost livrare</Typography>
                        <Typography variant="inherit"> {fCurrency(calculateShippingCost(priceWithoutShipping))} </Typography>

                    </Box>
                    <Divider/>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <Typography variant="h5"><b>Total</b></Typography>
                        <Typography sx={{color: 'red'}}
                                    variant="h5"><b> {fCurrency(totalCost)}</b></Typography>
                    </Box>

                    <Typography
                        variant="caption">Ti sa aplicat o reducere personalizata de
                        <b> {fCurrency(totalDiscount)} </b></Typography>
                    <Box>
                        <Typography
                            variant="caption">Livrare gratuita pentru comenzi peste <b> {fCurrency(SHIPING_COST_FREE)} </b>
                        </Typography>
                    </Box>

                </Grid>
            </Grid>
        </Card>
    );
}