import React, { useState } from 'react';
import { Box, Button, Typography, Snackbar } from '@mui/material';

const CookieConsent = () => {
    const [open, setOpen] = useState(localStorage.getItem('cookieConsent') !== 'true');

    const handleAccept = () => {
        // Set a cookie or localStorage to remember the user's consent
        localStorage.setItem('cookieConsent', 'true');
        setOpen(false);
    };

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            message={
                <Box display="flex" alignItems="center">
                    <Typography variant="body1" style={{ marginRight: '16px' }}>
                        Utilizăm doar cookies esențiale pentru funcționarea site-ului. Nu colectăm date pentru publicitate sau analiză. Continuând, accepți utilizarea acestora.                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleAccept}>
                        Accept
                    </Button>
                </Box>
            }
        />
    );
};

export default CookieConsent;