import * as React from 'react';
import {useEffect, useState} from 'react';
// material
import {Box, Grid} from '@mui/material';
// my components
import {CategoryCard} from "./components/CategoryCard"
import CategoryBreadcrumbs from "./components/CategoryBreadCrumbs"
// firebaseActions
import {
    getGroupAggregation,
    getDynamicGroupOptionsByName
} from "../../apiCalls/api/Products"
//functions
import {parseGroupData} from './functions/dtoParcer'
import {removeElementsAfterValue} from './functions/functions'
import {ProductList} from "../../sections/@dashboard/products";
import {addProductPrice} from "./functions/actions";
import {useAuth} from "../../Auth";
import Pagination from "../../components/Pagination";
import {useDispatch, useSelector} from "react-redux";

//redux store
import {setBreadcrumbRedux} from "../../store/products/productAction";
import Notiflix from "notiflix";

const BRANDS_CRUMBS_FIRST = "reseteaza";
const ROWS_PER_PAGE = 10;

export default function CatalogPage(props) {
    const {setHideFavorite} = props;
    const [currentCategory, setCurrentCategory] = useState([]);
    //   const [breadCrumbs, setBreadCrumbs] = useState([BRANDS_CRUMBS_FIRST]);
    const breadCrumbs = useSelector((state) => state.breadCrumb);
    const firstRender = React.useRef(true);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [from, setFromProduct] = useState(0);
    const [products, setProducts] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [reloadData, setReloadData] = useState(false);
    const {currentUser} = useAuth();

    useEffect(async () => {
        Notiflix.Loading.pulse('Se incarca...');

        if (breadCrumbs[0] === BRANDS_CRUMBS_FIRST && breadCrumbs.length === 1) {
            // in acest useEfect era doar aceasta functie daca foloseste sate in loc de redux
            await getGroups(1, setCurrentCategory)
            setProducts([])
        } else {
            await setGroupsAndProducts();
        }
        if (breadCrumbs.length > 1) {
            setHideFavorite(true);
        } else {
            setHideFavorite(false);
        }
    }, []);
    useEffect(async () => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        if (breadCrumbs.length > 1) {
            setHideFavorite(true);
        } else {
            setHideFavorite(false);
        }
        if (breadCrumbs[0] === BRANDS_CRUMBS_FIRST && breadCrumbs.length === 1) {
            await getGroups(1, setCurrentCategory)
            setProducts([])
        } else {
            await setGroupsAndProducts();
            Notiflix.Loading.remove();
        }


    }, [breadCrumbs, reloadData]);

    const setGroupsAndProducts = async () => {
        const result = await getDynamicGroupOptionsByName(breadCrumbs[breadCrumbs.length - 1], breadCrumbs.length - 1, breadCrumbs.length, from, ROWS_PER_PAGE);
        setCurrentCategory(parseGroupData(result));
        setTotalRows(result.data.hits.total.value);
        const productsWithPrice = await addProductPrice(result, currentUser);
        setProducts(productsWithPrice);
    }

    const getGroups = async (grupNumber, setGrup) => {
        const result = await getGroupAggregation(grupNumber);
        setGrup(parseGroupData(result));
    }


    function setBreadCrumbsData(data) {
        let dataBreadCrumbs = JSON.parse(JSON.stringify(breadCrumbs));
        dataBreadCrumbs.push(data);
        dispatch(setBreadcrumbRedux(dataBreadCrumbs));
    }

    function onClickCategory(category) {
        setPage(1)
        setTotalRows(0);
        setBreadCrumbsData(category.group_name);
    }

    const onBreadcrumbClick = (value) => {
        const newBreadCrumbs = removeElementsAfterValue(breadCrumbs, value);
        dispatch(setBreadcrumbRedux(newBreadCrumbs));
    }

    return (
        <Box>
            <Box sx={{m: 2}}>
                {breadCrumbs.length > 1 ? <CategoryBreadcrumbs
                    categories={breadCrumbs}
                    onClickCategory={(value) => {
                        onBreadcrumbClick(value);
                    }}
                /> : ""}

            </Box>

            <Box sx={{flexGrow: 1}}>
                <Grid container spacing={2}>
                    {currentCategory.map((row, index) => {
                        const itemsPerRow = 4;
                        const totalItems = currentCategory.length;
                        const isLastRow = Math.ceil((index + 1) / itemsPerRow) === Math.ceil(totalItems / itemsPerRow);
                        const itemsInLastRow = totalItems % itemsPerRow || itemsPerRow;
                        const xsValue = isLastRow ? Math.floor(12 / itemsInLastRow) : 3;
                        return <Grid key={index} item xs={xsValue}>
                            <CategoryCard
                                product={row}
                                onSelectItem={onClickCategory}
                            />
                        </Grid>
                    })}
                </Grid>
            </Box>

            <Box sx={{mt: 2}}>
                <ProductList products={products}/>
            </Box>

            {products.length > 0 ?
                <Pagination
                    totalRows={totalRows}
                    page={page}
                    totalRowsPerPage={ROWS_PER_PAGE}
                    handleChange={(from, newPage) => {
                        setPage(newPage);
                        setFromProduct(from);
                        setReloadData(!reloadData);
                    }}
                /> : ""}
        </Box>
    );
}
