import {getProductsPrice} from "../../../FireBase/actions";

export const addProductPrice = async (data,currentUser) => {

    let products = data.data.hits.hits;
    let productIds = []
    if (currentUser.login === false || currentUser.userDetails.status === false) {
        return products;
    }
    if (products.length === 0) {
        return []
    }

    products.map((row) => {
        productIds.push(row._source.SUPPLIER_ALT_AID)
    })

    const productsPrice = await getProductsPrice(productIds);

    products.map((product) => {
        const price = productsPrice.filter(proce => proce.SUPPLIER_ALT_AID_2 === product._source.SUPPLIER_ALT_AID)
        if (price.length > 0) {
            product._source.PRICE = price[0].PRICE
        }else {
            product._source.PRICE = null;

        }

    })
    return products
}

export const getProductPrice = async (id) => {
    const productsPrice = await getProductsPrice([id]);
    debugger;
    if (productsPrice.length > 0) {
        return productsPrice[0].PRICE
    } else {
        return null
    }
}