// NewsCard.js
import React from 'react';
import {Card, CardMedia, CardContent, Typography, Box, Button} from '@mui/material';
import {getAllNews} from "../apiCalls/api/news";
function replaceSpacesWithUnderscores(str) {
    return str.replace(/\s+/g, '_');
}

const NewsCard = (props) => {
    const [posts, setPosts] = React.useState([]);
    const {desplay} = props;

    React.useEffect(async () => {
        //let data = await getBlogs('desc', 'Noatate');
        let response = await getAllNews();
        if (desplay) {
            response.data = response.data.slice(0, desplay);
        }
        setPosts(response.data);
    },[]);
    return (
        <>
            {posts.map((news, index) => (

                <Card key={index} sx={{display: 'flex', flexDirection: 'column', mt: 2}}>
                    <CardMedia
                        component="img"
                        height="140"
                        image={news.cover}
                        alt={news.title}
                        sx={{
                            filter: 'grayscale(0%)',
                            transition: 'filter 0.5s ease',
                            '&:hover': {
                                filter: 'grayscale(100%)'
                            }
                        }}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {news.title}
                        </Typography>
                    </CardContent>
                    <Box sx={{p: 2}}>
                        <Button variant="contained" color="inherit" fullWidth href={'/noutati/' + news.id + "-" + replaceSpacesWithUnderscores(news.title) }>
                            citeste
                        </Button>
                    </Box>
                </Card>

            ))}

        </>


    );
};

export default NewsCard;