import React, {useEffect, useState} from "react";
import Page from '../../../components/Page';
import {Box, Button, Card, Container, Grid, Link, Stack, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {getDocumentById} from "../../../FireBase/actions";
import NotificationInfo from "../../../components/NotificationInfo";
import Footer from "../../../layouts/dashboard/Footer/Footer";
import NewsCard from "../../../components/NesCards";
import Iconify from "../../../components/Iconify";
import {useAuth} from "../../../Auth";
import Notiflix from "notiflix";
import {getNewsById} from "../../../apiCalls/api/news";

export default function PostDetails() {

    const [product, setProduct] = useState({
        title: "",
        content: ''
    });
    const[seo,setSeo] = useState({title: 'HNP: Noutati | Adaugare', description: '', keywords: ''});
    const {currentUser} = useAuth();
    const navigate = useNavigate();
    let {id} = useParams();

    useEffect(async () => {
        id = id.split('-')[0];
        Notiflix.Loading.pulse('Se incarca...');
        //const productData = await getDocumentById('blog', id);
        const response = await getNewsById(id);
        setSeo(prevSeo => ({
            ...prevSeo, // Menține eventualele valori anterioare
            title: response?.data?.title,
            description: response?.data?.title,
            keywords: response?.data?.title.replace(/ /g, ',')
        }));
        setProduct(response.data);
        Notiflix.Loading.remove();
    }, [])

    return (
        <Page data ={seo}>
            <Container maxWidth="xl">
                <NotificationInfo/>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {product.title}
                    </Typography>
                    {currentUser.userDetails && currentUser.userDetails.role === 'ROLE_ADMIN'
                        ? < Button
                            variant="text"
                            onClick={() => {
                                navigate('/editNews/'+id)
                            }}
                            startIcon={<Iconify icon="eva:material-symbols:edit"/>}
                        >
                            Modifică
                        </Button>
                        : ""
                    }
                </Stack>


                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Card
                            style={{minHeight: 340}}
                        >
                            <Grid margin={1} sx={{minHeight:692}}>
                                <div dangerouslySetInnerHTML={{__html: product.content}}/>
                            </Grid>

                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" component="h2" sx={{mb: 2, fontWeight: 'bold'}}>
                            Noutăți
                        </Typography>
                        <NewsCard desplay = {2}/>
                    </Grid>
                </Grid>

                <Box sx={{mt:2}}>
                    <Footer/>

                </Box>
            </Container>
        </Page>
    );
}