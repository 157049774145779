import React, {useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Card,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Modal,
    TextField,
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Iconify from "../../../components/Iconify";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {fCurrency} from "../../../utils/formatNumber";
import {BASE_URL_IMAGES} from "../../../utils/utils";
import {getCardImage} from "../../../functions";
import SearchPage from "../../catalog/SearchPage";
import {useAuth} from "../../../Auth";

export default function CartContent(props) {
    const {cart, handleAddToCart, handleRemoveQuantity, handleRemoveProduct, handleAddNewItem, viewMode,addProductToOrder } = props;
    const [openModal, setOpenModal] = useState(false);
    const {currentUser} = useAuth();
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    return (
        <Card>
            <Grid container>
                <Grid item xs={8} sx={{mt: 4, ml: 4}}>
                    <Typography variant="h4" sx={{mb: 5, display: 'flex'}}>
                        Cosul tau <Typography sx={{mt: 0.9, ml: 1}}> ( {cart.length} produse ) </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={3} sx={{mt: 4, ml: 4}}>
                    {(currentUser?.userDetails?.role === "ROLE_ADMIN" || currentUser?.userDetails?.role ==="ROLE_VANZARI" ) && addProductToOrder ?
                        <Button disabled={viewMode} variant="outlined" onClick={handleOpenModal}>ADAUGA IN COS UN PRODUS</Button> : ""}
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 970}} aria-label="simple table">
                    <TableHead sx={{backgroundColor: "rgb(244,246,248)"}} style={{borderRadius: '0'}}>
                        <TableRow>
                            <TableCell>Produs</TableCell>
                            <TableCell align="center">Pret</TableCell>
                            <TableCell align="left">Cantitate</TableCell>
                            <TableCell align="center">Pret Total</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cart.map((row) => (
                            <TableRow key={row.name} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row">
                                    <ListItem disableGutters sx={{py: 1.5, px: 2.5, mt: '1px'}}>
                                        <ListItemAvatar>
                                            <Avatar sx={{bgcolor: 'background.neutral', height: 60, width: 60}}>
                                                <img alt={row._source.DESCRIPTION_SHORT}
                                                     src={BASE_URL_IMAGES + getCardImage(row)}/>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={row._source.DESCRIPTION_SHORT}/>
                                    </ListItem>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
                                        {fCurrency(row._source.PRICE)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Box>
                                        <ButtonGroup sx={{mr: 2}} variant="outlined"
                                                     aria-label="outlined primary button group">
                                            <Button disabled={viewMode}
                                                    onClick={() => handleRemoveQuantity(row)}>-</Button>
                                            <Button disabled={viewMode} onClick={() => handleAddToCart(row)}>+</Button>
                                        </ButtonGroup>
                                        <Iconify icon="fluent-mdl2:quantity" sx={{mr: 0.5, width: 16, height: 16}}/>
                                        <b>{row.quantity + " x "}</b>
                                    </Box>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
                                        {fCurrency(row._source.PRICE * row.quantity)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton aria-label="delete" size="medium" disabled={viewMode}
                                                onClick={() => handleRemoveProduct(row)}>
                                        <DeleteIcon fontSize="inherit"/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={{bgcolor: 'background.paper', boxShadow: 24, p: 4, maxHeight: '80vh', overflowY: 'auto'}}>
                    <Typography variant="h6" component="h2">Adauga produs</Typography>
                    <SearchPage
                        handleAddProductToExistingOrder={handleAddNewItem}
                        addToOrder={true}
                    />

                </Box>
            </Modal>
        </Card>
    );
}