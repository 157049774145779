import {filter} from 'lodash';

import {useEffect, useState} from 'react';
// material
import {
    Button,
    Card,
    Checkbox,
    Container,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import {UserListHead, UserListToolbar, UserMoreMenu} from '../User/componenents';
import Modal from "../../components/Modal";
//
import Register from './form/Register';
import {getCollection} from "../../FireBase/actions";
import {parseDate} from "../../utils/utils";
import {getDistinctDiscountGroup} from "../../apiCalls/api/Products";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Notiflix from "notiflix";
// ----------------------------------------------------------------------
const TABLE_HEAD = [
    {id: 'name', label: 'Companie', alignRight: false},
    {id: 'reperezneetant', label: 'Reprezentant legal', alignRight: false},
    {id: 'telefon', label: 'Telefon', alignRight: false},
    {id: 'emailReprezentant', label: 'Email reprezentant', alignRight: false},
    {id: 'discount', label: 'Discount', alignRight: false},
    {id: 'consultantHnp', label: 'Consultant HNP', alignRight: false},
    {id: 'status', label: 'Status', alignRight: false},
    {id: 'dateAdd', label: 'Data adaugare', alignRight: false},
    {id: 'addBy', label: 'Adaugat de', alignRight: false},
    {id: ''},
];
// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

function DiscountGroupAccordion({ discountGroup }) {
    const [filterText, setFilterText] = useState('');

    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
    };

    const filteredDiscountGroup = discountGroup.filter(row =>
        row.key.toLowerCase().includes(filterText.toLowerCase())
    );

    return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Discount Groups</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField
                        label="Cauta dupa discount grup"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={filterText}
                        onChange={handleFilterChange}
                        sx={{ mb: 2 }}
                    />
                    {filteredDiscountGroup.map(row => (
                        <Chip key={row.key} label={`${row.key} - ${row.value}%`} />
                    ))}
                </AccordionDetails>
            </Accordion>
    );
}

export default function Partner() {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    //modal
    const [openModal, setOpenModal] = useState();
    const [loadData, setLoadData] = useState(1);
    //grid
    const [gridData, setGridData] = useState([]);
    const [discountGroup, setDiscountGroup] = useState([]);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = gridData.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - gridData.length) : 0;
    const filteredUsers = applySortFilter(gridData, getComparator(order, orderBy), filterName);
    const isUserNotFound = filteredUsers.length === 0;
    const getAllPartners = () => {
        Notiflix.Loading.pulse('Se incarca...',0);
        getCollection("partners").then((data) => {
            setGridData(data);
            Notiflix.Loading.remove(0);

        })
    }

    useEffect(() => {
        getAllPartners()

    }, [loadData]);

    useEffect(async () => {
       if (loadData > 1) {
            getAllPartners()
        }
        Notiflix.Loading.pulse('Se incarca...',1);
        const result = await getDistinctDiscountGroup()
        Notiflix.Loading.remove(1);

        setDiscountGroup(result.data.aggregations.distinct_values.buckets)
    }, []);

    return (
        <Page title="HNP Romania | Clienti">
            <Modal
                isOpen={openModal}
                setOpenModal={setOpenModal}
                title="Adauga client"
                setLoadData={setLoadData}
                loadData={loadData}

            >
                <Register
                    setLoadData={setLoadData}
                    loadData={loadData}
                    isOpen={openModal}
                    setOpenModal={setOpenModal}
                    discountGroupOptions={discountGroup}
                />
            </Modal>
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Clienti HNP
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill"/>}
                        onClick={() => setOpenModal(true)}
                    >
                        Creaza client
                    </Button>
                </Stack>
                <Card>
                    <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                    />

                    <Scrollbar>
                        <TableContainer sx={{minWidth: 800}}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={gridData.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            const {
                                                addBy,
                                                consultantEmail,
                                                consultantName,
                                                dateAdd,
                                                discountGroup,
                                                email,
                                                id,
                                                name,
                                                phone,
                                                reprezentantLegal,
                                                status
                                            } = row;
                                            const isItemSelected = selected.indexOf(name) !== -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            onChange={(event) => handleClick(event, name)}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            {/*<Avatar alt={name} src={''}/>*/}
                                                            <Typography variant="subtitle2" noWrap>
                                                                {name}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="left">{reprezentantLegal}</TableCell>
                                                    <TableCell align="left">{phone}</TableCell>
                                                    <TableCell align="left">{email}</TableCell>
                                                    <TableCell align="center" style={{minWidth:'250px'}}>
                                                        <DiscountGroupAccordion
                                                        discountGroup={discountGroup}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left">{consultantName}</TableCell>
                                                    <TableCell align="left">
                                                        <Label
                                                            variant="ghost"
                                                            color={(status === false && 'error') || 'success'}
                                                        >
                                                            {status === true ? "Activ" : "Inactiv"}
                                                        </Label>
                                                    </TableCell>
                                                    <TableCell align="center"
                                                               style={{minWidth: '120px'}}>{parseDate(dateAdd)}</TableCell>
                                                    <TableCell align="left">{addBy}</TableCell>
                                                    <TableCell align="right">
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{height: 53 * emptyRows}}>
                                            <TableCell colSpan={6}/>
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                                <SearchNotFound searchQuery={filterName}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={gridData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
