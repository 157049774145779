/*
export const createElkFilters = (data, index, from, size, searchText) => {

    /!*   const a = {
           "query": {
               "bool": {
                   "should": [
                       {"match": {"USER_DEFINED_EXTENSIONS.UDX_APPAREA": {"query": "Boring", "_name": "first"}}},
                       {"match": {"USER_DEFINED_EXTENSIONS.UDX_APPAREA": {"query": "Drilling", "_name": "first"}}}
                   ]
               }
           }
       };*!/

    let body = {
        "index": index,
        "body": {
            "query": {
                "bool": {
                    "must": [],
                },
            },
        },
        "from": from,
        "size": size,
    };
    if (data !== null) {
        const query = {
            "query": {
                "bool": {
                    "should": [],
                },
            },
        };
        data.UDX_APPAREA.forEach(value => {
            query.query.bool.should.push({"match": {"UDX.APPAREA": {"query": value}}})
        })
        body.body = query;
    }

    if (searchText) {
        body.body.query.bool.must = [];
        body.body.query.bool.must.push({
            "match_phrase": {
                "DESCRIPTION.LONG": {
                    "query": searchText
                }
            }
        })
    }


    return body;
};*/

/*export const createElkFilters = (data, index, from, size, searchText) => {
    debugger;
    let body = {
        "index": index,
        "body": {
            "query": {
                "bool": {
                    "must": [],
                    "should": []
                },
            },
        },
        "from": from,
        "size": size,
    };
    if (data !== null && data.UDX_APPAREA) {
        data.UDX_APPAREA.forEach(value => {
            body.body.query.bool.should.push({
                "match": {
                    "UDX.APPAREA": {
                        "query": value,
                        "fuzziness": "AUTO",
                        'max_expansions': 50, // Optional: specify the maximum number of variations
                        'prefix_length' : 1,
                    }
                }
            })
        });
    }

    if (searchText) {
        body.body.query.bool.must.push({
            "match": {
                "DESCRIPTION_LONG": {
                    "query": searchText,
                    "fuzziness": "AUTO",
                    'max_expansions': 50, // Optional: specify the maximum number of variations
                    'prefix_length' : 1, // Optional: specify the number of initial characters that will not be "fuzzified"
                }
            }
        });
    }

    return body;
};*/


/*const translations = {
    'Alezare': 'Boring',
    'Canelare/Debitare': 'Parting/Grooving',
    'Frezare': 'Milling',
    'Filetare': 'Threading',
    'Găurire': 'Drilling',
    'Lărgire': 'Reaming',
    'Prindere': 'Holding',
    'Strunjire': 'Turning',
    'Nu se aplică': 'Not applicable'
};*/

export const createElkFilters = (index, from, size, searchText) => {
    let body = {
        "index": index,
        "body": {
            "query": {
                "bool": {
                    "must": [],
                    "should": []
                },
            },
        },
        "from": from,
        "size": size,
    };
    if (searchText) {
        const searchField = searchText.includes('-') ? "MANUFACTURER_AID" : "DESCRIPTION_LONG";
        if (searchText.includes('-')) {
            body.body.query.bool.must.push({
                "term": {
                    "MANUFACTURER_AID.keyword": searchText
                }
            });
        } else {
            body.body.query.bool.must.push({
                "match": {
                    [searchField]: {
                        "query": searchText,
                        "fuzziness": "AUTO",
                        'max_expansions': 50,
                        'prefix_length': 1,
                    }
                }
            });
        }
    }
    return body;
};

export const createFilterFavoritesProductsById = (ids) => {
    return {
        "index": "elk-excel-index-new",
        "body": {
            "query": {
                "bool": {
                    "must": [
                        {
                            "terms": {
                                "MANUFACTURER_AID.keyword": ids
                            }
                        }
                    ],
                    "should": []
                },
            },
        },
    }
}
export const createAggregationQuery = (index, groupNumber) => {
    return {
        "index": index,
        "body": {
            "size": 0,
            "aggs": {
                "group_by_group_id": {
                    "terms": {
                        "field": "GROUP_ID_" + groupNumber + ".keyword",
                        "size": 1000
                    },
                    "aggs": {
                        "group_names": {
                            "terms": {
                                "field": "GROUP_NAME_" + groupNumber + ".keyword",
                                "size": 1000
                            }
                        },
                        "group_descriptions": {
                            "terms": {
                                "field": "MIME_SOURCE_GROUP_" + groupNumber + ".keyword",
                                "size": 1000
                            }
                        }
                    }
                }
            }
        }
    };
}
export const createAgregationDiscountGroup = (index) => {
    return {
        "index": index,
        "body": {
            "size": 0,
            "aggs": {
                "distinct_values": {
                    "terms": {
                        "field": "UDX.DISCOUNTGROUP.keyword",
                        "size": 10000
                    }
                }
            }
        }
    }
}

export const createAggregationQueryParentChild = (index, valueGroup, groupParent, groupChild, from, size) => {
    return {
        "index": index,
        "from": from,
        "size": size,
        "body": {
            "query": {
                "bool": {
                    "must": [
                        {
                            "term": {
                                [`GROUP_NAME_${groupParent}.keyword`]: {
                                    "value": valueGroup
                                }
                            }
                        }
                    ]
                }
            },
            "aggs": {
                "group_by_group_id": {
                    "terms": {
                        "field": `GROUP_ID_${groupChild}.keyword`,
                        "size": 1000
                    },
                    "aggs": {
                        "group_names": {
                            "terms": {
                                "field": `GROUP_NAME_${groupChild}.keyword`,
                                "size": 1000
                            }
                        },
                        "group_descriptions": {
                            "terms": {
                                "field": `MIME_SOURCE_GROUP_${groupChild}.keyword`,
                                "size": 1000
                            }
                        }
                    }
                }
            }
        }
    };
}

export const createAggregationQueryGroup2 = (index, groupNumber) => {
    return {
        "index": index,
        "body": {
            "size": 0,
            "query": {
                "bool": {
                    "must": [
                        {
                            "term": {
                                "GROUP_NAME_1.keyword": {
                                    "value": groupNumber
                                }
                            }
                        }
                    ]
                }
            },
            "aggs": {
                "group_by_group_id": {
                    "terms": {
                        "field": "GROUP_ID_2.keyword",
                        "size": 1000
                    },
                    "aggs": {
                        "group_names": {
                            "terms": {
                                "field": "GROUP_NAME_2.keyword",
                                "size": 1000
                            }
                        },
                        "group_descriptions": {
                            "terms": {
                                "field": "MIME_SOURCE_GROUP_2.keyword",
                                "size": 1000
                            }
                        }
                    }
                }
            }
        }
    };
}
export const createAggregationQueryGroup3 = (index, groupNumber) => {
    return {
        "index": index,
        "body": {
            "size": 0,
            "query": {
                "bool": {
                    "must": [
                        {
                            "term": {
                                "GROUP_NAME_1.keyword": {
                                    "value": groupNumber
                                }
                            }
                        }
                    ]
                }
            },
            "aggs": {
                "group_by_group_id": {
                    "terms": {
                        "field": "GROUP_ID_3.keyword",
                        "size": 1000
                    },
                    "aggs": {
                        "group_names": {
                            "terms": {
                                "field": "GROUP_NAME_3.keyword",
                                "size": 1000
                            }
                        },
                        "group_descriptions": {
                            "terms": {
                                "field": "MIME_SOURCE_GROUP_3.keyword",
                                "size": 1000
                            }
                        }
                    }
                }
            }
        }
    };
}


export const createQueryProductsByGroup = (index, from, size, group_id, value) => {
    let body = {
        "index": index,
        "body": {
            "query": {
                "bool": {
                    "must": [],
                    "should": []
                },
            },
        },
        "from": from,
        "size": size,
    };
    body.body.query.bool.must.push({
        "term": {
            [`GROUP_NAME_${group_id}.keyword`]: value
        }
    });
    return body;
}