import {useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// material
import {Menu, MenuItem, IconButton, ListItemIcon, ListItemText} from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import {setUserStatus} from "../../../FireBase/actions";
import Notiflix from "notiflix";
import {toLower} from "lodash";
// ----------------------------------------------------------------------

export default function UserMoreMenu(props) {
    const {setOpenModalAddUserCompany, setEditRow, row,getAllUsers,setOpenModalAddUserRoles} = props;
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const buttonName =  row.status ? 'Dezactiveaza' : 'Activeaza';
    const textName =  row.status ? 'dezactivarea' : 'activarea';

    const setUSerStatus = async () => {

        const succes = await setUserStatus(row)
        if (succes) {
            Notiflix.Notify.success('Statusul a fost schimbat cu succes');
            getAllUsers();
        } else {
            Notiflix.Notify.failure('A aparut o eroare');
        }

    }

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Iconify icon="eva:more-vertical-fill" width={20} height={20}/>
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: {width: 220, maxWidth: '100%'},
                }}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <MenuItem sx={{color: 'text.secondary'}}
                          onClick={async () => {
                              Notiflix.Confirm.show(
                                  'Confirmare',
                                  'Sunteți sigur că doriți să '+toLower(textName)+' userul ' + row.firstName + row.lastName + '?',
                                  'Da',
                                  'Nu',
                                  async () => {
                                      await setUSerStatus();
                                  },
                                  () => {
                                      Notiflix.Notify.info('Actiunea a fost anulată.');
                                  }
                              );

                          }}
                >
                    <ListItemIcon>
                        <Iconify icon="eva:trash-2-outline" width={24} height={24}/>
                    </ListItemIcon>
                    <ListItemText primary={buttonName} primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>

                <MenuItem sx={{color: 'text.secondary'}} onClick={() => {
                    setEditRow(row);
                    setOpenModalAddUserCompany(true);
                }}>
                    <ListItemIcon>
                        <Iconify icon="eva:edit-fill" width={24} height={24}/>
                    </ListItemIcon>
                    <ListItemText primary="Asocieaza companie" primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>
                <MenuItem sx={{color: 'text.secondary'}} onClick={() => {
                    setEditRow(row);
                    setOpenModalAddUserRoles(true);
                }}>
                    <ListItemIcon>
                        <Iconify icon="eva:people-outline" width={24} height={24}/>
                    </ListItemIcon>
                    <ListItemText primary="Atribuie rol" primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>
            </Menu>
        </>
    );
}
