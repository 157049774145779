import {saveFirebaseDocument} from "../../../../../FireBase/actions";

export const saveCustomerDeliveryAddress = async (data, callback) => {
    try {
        await saveFirebaseDocument('customerDeliveryAddress', data)
        window.location.reload();
        if (callback) callback();
    } catch (ex) {

    }
}