import request from '../../axios/MyAxios';

const PATH = {
    SEND_EMAIL: '/hnp/email/send',
}
export const sendEmail = async (body) => {
    return request({
        method: "POST",
        url: PATH.SEND_EMAIL,
        data: body,
    });
}