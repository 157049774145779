export function parseGroupData(data) {
    let newFormat = []
    data.data.aggregations.group_by_group_id.buckets.map((row) => {
        newFormat.push({
            doc_count: row.doc_count,
            img: row.group_descriptions.buckets.length > 0 ? row.group_descriptions.buckets[0].key : "",
            group_name: row.group_names.buckets.length > 0 ? row.group_names.buckets[0].key : "",

        })

    })
    return newFormat;
}