import React, { useContext, useEffect, useState } from "react";
import { auth } from "./FireBase/base";
import {
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
} from "firebase/auth";
import { getDocumentById, getDocumentProperty } from "./FireBase/actions";
import { INITIAL_STATE } from "./constants/constants";
import { useNavigate } from "react-router-dom";

const AuthContext = React.createContext(null);

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(INITIAL_STATE);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Reîmprospătarea datelor utilizatorului
    async function refreshUserData() {
        const user = auth.currentUser;
        if (user) {
            try {
                // Reîmprospătăm datele utilizatorului
                await user.reload();  // Se reîncarcă obiectul utilizatorului din Firebase

                // După reload, obținem cele mai recente date
                const updatedUser = auth.currentUser;

                // Verificăm dacă utilizatorul este autenticat și dacă a fost validat email-ul
                if (updatedUser) {
                    const userDetails = await getDocumentProperty("users", 'userId', updatedUser.uid);
                    if (userDetails.companyId) {
                        updatedUser.companyDetails = await getDocumentById("partners", userDetails.companyId);
                    }
                    updatedUser.login = true;
                    updatedUser.userDetails = userDetails;

                    setCurrentUser(updatedUser);  // Actualizăm state-ul cu utilizatorul actualizat
                }
            } catch (error) {
                console.error("Eroare la reîmprospătarea datelor utilizatorului:", error);
            }
        }
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const userDetails = await getDocumentProperty("users", 'userId', user.uid);
                if (userDetails.companyId) {
                    user.companyDetails = await getDocumentById("partners", userDetails.companyId);
                }
                user.login = true;
                user.userDetails = userDetails;
                setCurrentUser(user);
            } else {
                setCurrentUser(INITIAL_STATE);
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    async function logout() {
        await signOut(auth);
        navigate('/', { replace: true });
        setCurrentUser(INITIAL_STATE);
        return true;
    }

    function resetPassword(email) {
        return sendPasswordResetEmail(auth, email);
    }

    const value = {
        currentUser,
        login,
        signup,
        logout,
        resetPassword,
        refreshUserData,  // Expunem funcția de reîmprospătare a datelor
        setCurrentUser,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
