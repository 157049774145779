// Footer.js

import React from 'react';
import { Container,Card, Grid, Typography, Link, Box } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
    return (
        //#4a4d50
        //backgroundColor: 'primary.main'
        <Card sx={{backgroundColor: '#4a4d50', color: 'white'}}>
        <Box component="footer" sx={{ py: 3, mt: 'auto'}}>
            <Container maxWidth="lg" >
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" component="div">
                            <RouterLink to="/despre_noi" style={{ textDecoration: 'none', color: 'inherit' }}>
                                Despre HNP România
                            </RouterLink>
                        </Typography>

                        <Typography variant="body2" sx={{mt: 1}}>
                            Suntem unici distribuitori pe piața din România ai reputaților producători BASS, EBERLE, SCM, SMICUT, SPECIALINSERT, TECNOSPIRO si parteneri WALTER pentru zona de Vest și Sud Vest a României.
                        </Typography>
                        <Typography variant="body2" sx={{mt: 1}}>
                            <RouterLink to="/despre_noi" style={{  color: 'inherit' }}>
                                Mai multe despre noi
                            </RouterLink>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" component="div">
                            Contactează-ne
                        </Typography>
                        <Typography variant="body2" sx={{mt: 1}}>
                            Email: office@hnp.ro
                            <br />
                            Telefon: + 40 744 774 500
                        </Typography>
                        <Typography variant="h6" component="div" sx={{mt: 2}}>
                            Urmărește-ne
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                            <Link href="https://www.facebook.com/HNPro" target="_blank" color="inherit">
                                <FacebookIcon />
                            </Link>
                            <Link href="https://linkedin.com/company/hnpromania" target="_blank" color="inherit">
                                <LinkedInIcon />
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" component="div">
                            Mai multe servicii
                        </Typography>
                        <Typography variant="body2" sx={{mt: 1}}>
                            Soluții complete pentru prelucrarea metalelor
                            <br />
                            Consultanță tehnică
                            <br />
                            Instalare
                            <br />
                            Testare și mentenanță
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        </Card>
    );
};

export default Footer; 
