export function getCardImage(product) {

    if(product._source.MIMESOURCENORMALFILE2 === null) {
        return product._source.MIMESOURCENORMALFILE1;
    }
    return product._source.MIMESOURCENORMALFILE2;
}
export function trimText(text, maxLength) {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
}