import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const auth = getAuth();

export const resetPassword = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        console.log('Password reset email sent!');
    } catch (error) {
        console.error('Error sending password reset email:', error);
    }
};