import React from 'react';
import Page from '../components/Page';
import {Container, Grid, Typography, Card, Box, Link, CardMedia, CardContent, Button} from '@mui/material';
import Footer from "../layouts/dashboard/Footer/Footer";
import NewsCard from "../components/NesCards";
import {getAllNews} from "../apiCalls/api/news";

const partners = [
    {
        name: 'BASS GMBH & Co. KG',
        description: 'Tarozi Freze pentru filetare.',
        story: ' BASS GMBH & Co. KG. este o firmă de familie, foarte dinamică, din localitatea Niederstetten, din sudul Germaniei. BASS produce scule de înaltă calitate pentru filetare, aprovizionând piața germană și cea internațională încă din anul 1947.',
        logo: '/static/HNP-PARTNERS/bass.jpg',
        link: 'https://www.bass-tools.com/',
        news: 'Cooperarea dintre BASS și HNP oferă clienților nostri solutii de înaltă performanță, eficiente și cu o largă aplicabilitate. Parteneriatul nostru are ca rezultat satisfacția clienților raportată la servicii și produse de înaltă calitate.',
    },
    {
        name: 'DUECI SRL',
        description: 'Freze Burghie Alezoare. ',
        story: 'DUECI SRL a fost fondată în anul 1995, ca producător de top a sculelor de carbură monobloc: freze, burghie și alezoare.',
        logo: '/static/HNP-PARTNERS/Dueci_prod.jpg',
        link: 'https://www.dueci.com/',
        news: '“Suntem specializați atât în scule standard, cât și în scule produse pe comandă, la cererea clienților. Investim continuu în mașini de ultimă generație, personal super-calificat și selectăm cea mai bună carbură ca materie primă. Toate acestea ne garantează standarde ridicate, prețuri competitive și servicii de calitate pentru clienții nostri”.',
    },
    {
        name: 'Canela',
        description: 'Scule pentru strunjire Găurire și frezare cu plăcuțe indexabile.',
        story: ' Grupul Canela a fost înființat în anul 1967 de către Ramon Canela. Activitatea inițială era sudare și prelucrări subcontractate, dar începând cu 1974, producerea sculelor cu plăcuțe indexabile a devenit activitatea de bază.',
        logo: '/static/HNP-PARTNERS/2.jpg',
        link: 'https://canelatools.com/',
        news: 'In 1977 a fost fondată firma Microfusió Catalana, demarând turnarea metalelor ca proces adițional în producerea sculelor. Acesta a fost începutul unei mari afaceri de familie, condusă de Ramon Canela împreună cu sotia sa, Maria Rosa Vinyes și cei doi doi copii ai lor, Alex Canela și Silvia Canela. Toti patru continuă în prezent această afacere. În zilele noastre, Canela este singurul producator de scule de această anvergura, care nu este o societate pe actiuni. Calitatea superioară a produselor Canela este binecunoscută în întreaga lume.',
    },
    {
        name: 'J.N. EBERLE & CIE. GMBH',
        description: 'Benzi pentru debitare. ',
        story: 'J.N. EBERLE & CIE. GMBH – ”Performanță prin precizie”, este lider mondial în producția de benzi de debitare și benzi de otel de înaltă precizie. Din anul 1836 întregul ciclu de fabricație are loc exclusiv în fabrica proprie din Augsburg, Bavaria, motiv pentru care oferă soluții de debitare la tema.',
        logo: '/static/HNP-PARTNERS/Eberle_prod.jpg',
        link: 'https://www.eberle-augsburg.de',
        news: 'EBERLE produce benzi de debitare cu o gama variata de tratamente termice si acoperiri, în funcție de cerința clientilor, ajutandu-i la creșterea productivității și scăderea cheltuielilor. Crezul EBERLE este acela de a găsi împreună banda optimă pentru aplicația și materialul clientului!',
    },
    {
        name: 'GERARDI',
        description: 'Menghine / Dispozitive și sisteme modulare pentru poziționare, orientare și fixare / Capete unghiulare / Scule antrenate.',
        story: ' Gerardi S.p.A este prima firmă italiană care proiectează și dezvoltă sisteme modulare complete pentru poziționarea, orientarea și fixarea semifabricatelor pe masa mașinii unelte.',
        logo: '/static/HNP-PARTNERS/GERARDI.png',
        link: 'https://www.gerardispa.com/products/',
        news: 'Ivano Gerardi Sr, bazându-se pe experiența a 20 de ani în industria din Canada și SUA, își înființează propria firmă în Italia, în anul 1971. Devine cel mai mare producător italian de menghine începând cu anul 1980. Tot acum dezvoltă diverse tipuri de capete unghiulare și o gamă variată de scule antrenate pentru mașini unelte și centre de prelucrare. Începând cu anul 2000 se deschide filiala din Statele Unite și puncte de lucru în Germania și Marea Britanie. Filiala din India, dedicată pieței locale, se deschide în anul 2012. 2015 este un an de referință. Cu un număr de 200 de angajați, exporturile ajung să reprezinte 70% din producție, fiind acoperite 50 de țări.',
    },
    {
        name: 'Rime',
        description: 'Freze, alezoare și scule speciale din HSS și carbură metalică. ',
        story: 'Fondată în anul 1962 de Dl. Massimiliano Ettori, Rime SRL și-a început activitatea producând scule așchietoare pentru industria de armament. Firma este condusă în prezent de Andrea Ettori, fiul fondatorului, iar gama de freze produse s-a dezvoltat continuu, adresându-se sectoarelor: energetic, construcții de mașini, armament, aeronautică, matrițerie.',
        logo: '/static/HNP-PARTNERS/rime_prod.jpg',
        link: 'https://www.rime.net/en/',
        news: 'Datorită parcului modern de mașini unelte, firma garantează clienților săi calitate și flexibilitate.',
    },
    {
        name: 'S.C.M.',
        description: 'Adaptoare și bucse pentru sculele de aschiere.',
        story: ' S.C.M. este o firmă italiană cu tradiție, fondată în 1972 de frații Eugenio și Angelo Masoero în Murisengo, NV Italiei. Produsul de bază este proiectarea și producția de adaptoare și bucse pentru sculele de aschiere.',
        logo: '/static/HNP-PARTNERS/SCM_prod.jpg',
        link: 'https://www.scmsrl.com/',
        news: 'SCM este certificată ISO 9001 din anul 2000, fiind prezenți în Europa, SUA, Canada, America de Sus, China și Estul Asiei.',
    },
    {
        name: 'SMICUT Suedia',
        description: 'Scule pentru filetare prin strunjire și frezare, cu placate amovibile Freze de filetare din carbora monobloc. ',
        story: 'Cu 50 de ani în urmă, familia SCHMIDT a început perfecționarea și producția sculelor de aschiere. Astăzi, în aceasta companie suedeză lucreaza 3 generații. Sculele de filetare au fost dintotdeauna produsul lor de bază, care au reprezentat familia Schmidt.',
        logo: '/static/HNP-PARTNERS/smicut_prod.jpg',
        link: 'https://smicut.com/',
        news: 'SMICUT este astăzi focusat pe filetarea prin frezare, ca metoda modernă și eficientă pentru obținerea filetelor.',
    },
    {
        name: 'TECNOSPIRO',
        description: 'Masini de filetat ROSCAMAT.',
        story: ' TECNOSPIRO este parte a grupului SPIROIDE, producător de rulmenți și transmisii mecanice pentru echipamente industriale. Primul brat de filetare a fost fabricat de TECNOSPIRO în anul 1987, pentru a ajuta propria producție.',
        logo: '/static/HNP-PARTNERS/roscamat_prod.jpg',
        link: 'https://tecnospiromt.com',
        news: '“Atunci nu era un scop în sine să devenim producatori pentru acest tip de masini, dar cererea pieței a facut să evoluăm în această direcție”. Simplitatea, versatilitatea, ușurința instalării și cuplării între posturile de lucru transformă mașinile ROSCAMAT în unități indispensabile în producție; sunt foarte utile la prelucrarea pieselor supradimensionale, matrițe de dimensiuni mari, care pot tine și loc de masă incorporată.',
    },
    {
        name: 'WALTER TOOLS',
        description: 'Scule pentru strunjire, găurire, frezare.',
        story: ' Doriti să vă aduceți tehnologia la nivelul următor? Este usor cu soluțiile care se situează mult deasupra standardelor.',
        logo: '/static/HNP-PARTNERS/Walter_prod.jpg',
        link: 'https://www.walter-tools.com/ro-ro',
        news: 'Fiecare dintre inovațiile Walter reprezintă experiența noastră colectivă și expertiza în inginerie – pentru ca dumneavoastră să va puteți extinde continuu succesul și progresele. “Optați pentru soluțiile competente oferite de HNP Tools – distribuitor unic Walter pentru zona de V și S-V a României”.',

    },
];
const PartnersPage = () => {

    return (
        <Page title="HNP Romania | Parteneri">
            <Container maxWidth="xl">
                <Typography variant="h4" component="h1" sx={{mt: 4, mb: 4, textAlign: 'left'}}>
                    Produse Parteneri
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        {partners.map((partner, index) => (
                            <Grid item xs={12} md={12} key={index}>
                                <Card sx={{display: 'flex', flexDirection: 'column', boxShadow: 3, mb: 4}}>
                                    <Box sx={{mb: 2}}>
                                        <Link href={partner.link} target="_blank" rel="noopener noreferrer">
                                            <img
                                                src={partner.logo}
                                                alt={partner.name}
                                                style={{width: '100%', objectFit: 'cover'}}
                                            />
                                        </Link>
                                    </Box>
                                    <Box sx={{p: 2}}>
                                        <Link href={partner.link} target="_blank" rel="noopener noreferrer"
                                              underline="none" color="inherit">
                                            <Typography variant="h6" component="h2" sx={{mb: 1, fontWeight: 'bold'}}>
                                                {partner.name}
                                            </Typography>
                                        </Link>
                                        <Typography variant="subtitle1" sx={{mb: 1}}>
                                            {partner.description}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" sx={{mb: 1}}>
                                            {partner.story}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {partner.news}
                                        </Typography>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" component="h2" sx={{mb: 2, fontWeight: 'bold'}}>
                            Noutăți
                        </Typography>
                        <NewsCard/>
                    </Grid>
                </Grid>
            <Footer/>
            </Container>
        </Page>
    );
};

export default PartnersPage;