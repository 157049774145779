import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, TextField, Button } from '@mui/material';
import AuthLayout from '../layouts/AuthLayout';
import Page from '../components/Page';
import ReCAPTCHA from 'react-google-recaptcha';
import {resetPassword} from "../utils/reset-password";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '15px', // Set padding-bottom to 15px


}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '15px', // Set padding-bottom to 15px

}));

// ----------------------------------------------------------------------

export default function ResetPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const handleResetPassword = async (event) => {
        event.preventDefault();
        if (!recaptchaToken) {
            setMessage('Vă rugăm să completați reCAPTCHA.');
            return;
        }
        try {
            await resetPassword(email);
            setMessage('Emailul pentru resetarea parolei a fost trimis! Verificați căsuța de email.');
        } catch (error) {
            setMessage('Eroare la trimiterea emailului pentru resetarea parolei.');
        }
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    return (
        <RootStyle title="Resetează Parola | HNP">
            <AuthLayout>
                Ai deja un cont HNP? &nbsp;
                <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
                    Conectează-te
                </Link>
            </AuthLayout>

            <SectionStyle sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                    Resetează parola
                </Typography>
                <img src="/static/logo.svg" alt="reset password" />
            </SectionStyle>

            <Container maxWidth="sm">
                <ContentStyle>
                    <Stack sx={{ mb: 5 }}>
                        <Typography variant="h4" gutterBottom>
                            Resetează parola contului tău HNP
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Introdu adresa de email</Typography>
                    </Stack>
                    <form onSubmit={handleResetPassword}>
                        <Stack spacing={3}>
                            <TextField
                                fullWidth
                                type="email"
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <ReCAPTCHA
                                sitekey="6LeCn9MZAAAAAE-J2tSDqa15Qrl2lzrgPXEjWXp0"
                                onChange={handleRecaptchaChange}
                            />
                            <Button type="submit" variant="contained" fullWidth>
                                Trimite email de resetare
                            </Button>
                        </Stack>
                    </form>
                    {message && (
                        <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                            {message}
                        </Typography>
                    )}
                </ContentStyle>
            </Container>
        </RootStyle>
    );
}