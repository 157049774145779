// routes
// import Router from './routes';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import {BaseOptionChartStyle} from './components/charts/BaseOptionChart';
import {AuthProvider} from "./Auth";
// ----------------------------------------------------------------------
import {configureStore} from "./store/configureStore";
import {Provider} from "react-redux";
import Login from "./pages/Login";
import Register from "./pages/Register";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import DashboardApp from "./pages/DashboardApp/DashboardApp";
import Products from './pages/Products';
import Index from './pages/Blog';
import User from './pages/User';
import Company from './pages/Company';
import NotFound from './pages/Page404';
import DashboardLayoutAuth from "./layouts/dashboard/DashboardAuth";
import {ProtectedRoute} from "./PrivateRoute";
import UploadFile from './pages/UploadFile';
import NewPost from './pages/Blog/NewPost';
import ProductDetails from './pages/ProductDetails';
import Partners from './pages/Partners';
import BlogDetails from './pages/Blog/BlogDetails'
import DashboardLayoutFree from "./layouts/dashboard/DashboardFree";
import Homepage from "./pages/home-page/Homepage";
import Order from './pages/Order/Order';
import OrderDetails from "./pages/Order/OrderDetails";
import CustomerDashboard from "./pages/CustomerDashboard/CustomerDashboard";
import ConfirmEmail from "./pages/ConfirmEmail";
import Contact from './pages/Contact/Contact';
import DespreNoi from "./pages/DespreNoi/DespreNoi";
import CatalogPage from "./pages/catalog/CatalogPage";
import SearchPage from "./pages/catalog/SearchPage";
import ResetPassword from "./pages/ResetPassword";
import {useEffect} from "react";
import {logPageView} from "./FireBase/actions";


export default function App() {

    const location = useLocation();

    useEffect(() => {
        logPageView()
    }, [location]);

    return (
        <ThemeConfig>
            <Provider store={configureStore}>
                <AuthProvider>
                    <ScrollToTop/>
                    <GlobalStyles/>
                    <BaseOptionChartStyle/>
                    <Routes>
                        <Route element={<ProtectedRoute/>}>
                            <Route path="/user"
                                   element={<DashboardLayoutAuth admin={true}><User/></DashboardLayoutAuth>}/>
                            <Route path="/Company"
                                   element={<DashboardLayoutAuth admin={true}><Company/></DashboardLayoutAuth>}/>
                            <Route path="/uploadFile"
                                   element={<DashboardLayoutAuth admin={true}><UploadFile/></DashboardLayoutAuth>}/>

                            <Route path="/addNews"
                                   element={<DashboardLayoutAuth admin={true}><NewPost/></DashboardLayoutAuth>}/>
                            <Route path="/editNews/:id"
                                   element={<DashboardLayoutAuth admin={true}><NewPost/></DashboardLayoutAuth>}/>
                            <Route path="/orderDetails/:orderId"
                                   element={<DashboardLayoutAuth admin={false}><OrderDetails/></DashboardLayoutAuth>}/>
                            <Route path="/contulMeu"
                                   element={<DashboardLayoutAuth
                                       admin={false}><CustomerDashboard/></DashboardLayoutAuth>}/>
                            <Route path="/app"
                                   element={<DashboardLayoutAuth
                                    admin={true}
                                   ><DashboardApp/></DashboardLayoutAuth>}/>
                        </Route>
                        <Route index element={<DashboardLayoutFree><Homepage/></DashboardLayoutFree>}/>
                        <Route path="/homepage"
                               element={<DashboardLayoutFree admin={false}><Homepage/> </DashboardLayoutFree>}/>
                        <Route path="/despre_noi"
                               element={<DashboardLayoutFree admin={false}><DespreNoi/> </DashboardLayoutFree>}/>
                        <Route path="/contact"
                               element={<DashboardLayoutFree admin={false}><Contact/></DashboardLayoutFree>}/>
                        <Route path="/products"
                               element={<DashboardLayoutFree admin={true}><SearchPage/></DashboardLayoutFree>}/>
                        <Route path="/partners"
                               element={<DashboardLayoutFree admin={true}><Partners/></DashboardLayoutFree>}/>

                        <Route path="/noutati"
                               element={<DashboardLayoutFree admin={false}><Index/></DashboardLayoutFree>}/>
                        <Route path="noutati/:id"
                               element={<DashboardLayoutFree admin={false}><BlogDetails/> </DashboardLayoutFree>}/>
                        <Route path="oferta/:id"
                               element={<DashboardLayoutFree admin={false}><BlogDetails/> </DashboardLayoutFree>}/>
                        <Route path="/cartDetails"
                               element={<DashboardLayoutFree admin={false}><Order/></DashboardLayoutFree>}/>
                        <Route path="detaliiProdus/:name/:id" element={<DashboardLayoutFree admin={false}>
                            <ProductDetails/>
                        </DashboardLayoutFree>}/>
                        <Route path="login" element={<LogoOnlyLayout><Login/></LogoOnlyLayout>}/>
                        <Route path="reseteaza" element={<LogoOnlyLayout><ResetPassword/></LogoOnlyLayout>}/>
                        <Route path="register" element={<LogoOnlyLayout><Register/></LogoOnlyLayout>}/>
                        <Route path="404" element={<LogoOnlyLayout><NotFound/></LogoOnlyLayout>}/>
                        <Route path="confirm/email/address/hnp/:id"
                               element={<LogoOnlyLayout><ConfirmEmail/></LogoOnlyLayout>}/>
                        <Route path="*" element={<Navigate to="/404" replace/>}/>

                    </Routes>
                </AuthProvider>
            </Provider>
        </ThemeConfig>
    );
}