import React, {useState} from 'react';
import {Container, TextField, Typography, Stack, Grid, Box, Link} from '@mui/material';
import * as Yup from 'yup';
import Notiflix from 'notiflix';
import {saveBlog} from './actions'
import {LoadingButton} from "@mui/lab";
import {FormikProvider, useFormik} from "formik";
import NewsCard from "../../components/NesCards";
import Footer from "../../layouts/dashboard/Footer/Footer";
import ReCAPTCHA from 'react-google-recaptcha';
import Page from "../../components/Page";

const AddressBlock = () => {
    return (
        <Box sx={{paddingTop: 0, paddingBottom: 0}}>
            <Box sx={{paddingTop: 0, paddingBottom: 0}}>
                <Box id="google-map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2784.839390352069!2d21.258997515566485!3d45.73431567910504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa69607882b22fc19!2sHNP!5e0!3m2!1sro!2sro!4v1601820133958!5m2!1sro!2sro"
                        style={{border: 0}}
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                        width="100%"
                        height="450"
                    ></iframe>
                </Box>
            </Box>
        </Box>
    );
};

const AddressDetails = () => {
    return (
        <Box sx={{paddingTop: 5}}>
            <Typography>307220 Luceafarului nr.3, Giroc</Typography>
            <Typography>
                <span>T</span> <Link href="tel:+40744774500" color="inherit">+ 40 744 774 500</Link>
            </Typography>
            <Typography>
                <span>F</span> <Link href="tel:+40256220727" color="inherit">+40 256 220 727</Link>
            </Typography>
            <Typography>
                <span>E</span> <Link href="mailto:office@hnp.ro"color="inherit">office&#64;hnp&#46;ro</Link>
            </Typography>
        </Box>
    );
};

const ContactSchema = Yup.object().shape({
    nume: Yup.string().min(2, 'Prea scurt!').max(50, 'Prea lung!').required('Numele este obligatoriu'),
    email: Yup.string().email('Email-ul trebuie să fie valid').required('Email-ul este obligatoriu'),
    numarTelefon: Yup.string().min(6, 'Prea scurt!').required('Numărul de telefon este obligatoriu'),
    numeCompanie: Yup.string().min(2, 'Prea scurt!').required('Numele companiei este obligatoriu'),
    subject: Yup.string().min(2, 'Prea scurt!').required('Subiectul este obligatoriu'),
    mesaj: Yup.string().min(10, 'Prea scurt!').required('Mesajul este obligatoriu'),
});
export default function ContactPage() {
    const formik = useFormik({
        initialValues: {
            nume: '',
            email: '',
            numarTelefon: '',
            numeCompanie: '',
            subject: '',
            mesaj: '',
        },
        validationSchema: ContactSchema,
        onSubmit: (values) => {
            if (!recaptchaToken) {
                Notiflix.Notify.failure('Vă rugăm să completați reCAPTCHA.');
                return;
            }
            Notiflix.Confirm.show(
                'Confirmare',
                'Sunteți sigur că doriți să trimiteți mesajul?',
                'Da',
                'Nu',
                async () => {
                    console.log(values);
                    await saveBlog(values);
                    Notiflix.Notify.success('Mesajul a fost trimis cu succes!');
                },
                () => {
                    Notiflix.Notify.info('Trimiterea mesajului a fost anulată.');
                }
            );
        },
    });
    const {errors, touched, handleSubmit, getFieldProps} = formik;
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
        setIsButtonDisabled(!token);
    };
    return (
        <Page title="HNP Romania | Contact">

        <Container maxWidth="xl">

            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Typography variant="h4" align="left" gutterBottom>
                        Trimite un mesaj
                    </Typography>
                    <Typography sx={{p: 2}}>
                        Clienții noștri își desfasoară activitatea în domeniul prelucrărilor prin așchiere a
                        semifabricatelor
                        din materiale feroase și neferoase, la nivel industrial. Ne adresăm atât firmelor mari, cu
                        producție de
                        serie mare, cât și firmelor mici, producătoare de unicate, firme care au în comun necesitatea de
                        a
                        obține calitate și precizie la piesele finale. Nu ezitați să ne contactați, vă stăm la
                        dispoziție, cu
                        profesionalism și cu cele mai bune soluții tehnice pentru proiectele
                        dumneavoastră! </Typography>
                    <FormikProvider value={formik}>
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={3}>
                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Nume *"
                                        {...getFieldProps('nume')}
                                        error={Boolean(touched.nume && errors.nume)}
                                        helperText={touched.nume && errors.nume}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Email *"
                                        {...getFieldProps('email')}
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                </Stack>
                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Numar de telefon *"
                                        {...getFieldProps('numarTelefon')}
                                        error={Boolean(touched.numarTelefon && errors.numarTelefon)}
                                        helperText={touched.numarTelefon && errors.numarTelefon}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Nume companie *"
                                        {...getFieldProps('numeCompanie')}
                                        error={Boolean(touched.numeCompanie && errors.numeCompanie)}
                                        helperText={touched.numeCompanie && errors.numeCompanie}
                                    />
                                </Stack>
                                <TextField
                                    fullWidth
                                    autoComplete="username"
                                    label="Subject *"
                                    {...getFieldProps('subject')}
                                    error={Boolean(touched.subject && errors.subject)}
                                    helperText={touched.subject && errors.subject}
                                />
                                <TextField
                                    fullWidth
                                    label="Mesaj *"
                                    multiline
                                    rows={4}
                                    {...getFieldProps('mesaj')}
                                    error={Boolean(touched.mesaj && errors.mesaj)}
                                    helperText={touched.mesaj && errors.mesaj}
                                />
                                <ReCAPTCHA
                                    sitekey="6LeCn9MZAAAAAE-J2tSDqa15Qrl2lzrgPXEjWXp0"
                                    onChange={handleRecaptchaChange}
                                    onExpired={() => setRecaptchaToken(null)}
                                />

                                <LoadingButton
                                    sx={{width: 300}}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    disabled={isButtonDisabled}

                                >
                                    Trimite mesajul
                                </LoadingButton>
                            </Stack>
                        </form>
                    </FormikProvider>
                </Grid>
                <Grid item xs={4}>
                   {/* <NewsCard/>*/}
                    <AddressBlock/>
                    <AddressDetails/>

                </Grid>
                <Grid xs={12} sm={12} sx={{mt: 4}}>
                    <Footer/>

                </Grid>
            </Grid>

        </Container>
        </Page>
    );
}