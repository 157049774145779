import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import {forwardRef, useEffect} from 'react';
// material
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({children, data = null, title = '', ...other}, ref) => (
    useEffect(() => {
        console.log(data);
        debugger;
    }, [data]),
    <Box ref={ref} {...other}>
        <Helmet>
            <title>{title}</title>
            {data ? <>
                <title>{` ${data?.title}`}</title>
                <meta name="description" content={ data?.description}/>
                <meta name="keywords"
                      content={data?.keywords.replace(/ /g, ',') }/>
                <meta name="author" content="Rosu Catalin Dorin"/>
                <meta name="linkedin"
                      content="https://www.linkedin.com/in/ro%C8%99u-c%C4%83t%C4%83lin-dorin-872322112/"/>
            </> : <title>{title}</title>}
        </Helmet>
        {children}
    </Box>
));

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string
};

export default Page;
