import {filter} from 'lodash';
import React, {useEffect, useState} from 'react';
// material
import {
    Avatar,
    Button,
    Card,
    Checkbox,
    Container, FormControlLabel, Radio, RadioGroup,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
//Module components
import {UserListHead, UserListToolbar, UserMoreMenu,AddCompanyModal,AddUserRoleModal} from './componenents';
//firebase
import {getCollection} from "../../FireBase/actions";
//utils
import {parseDate,descendingComparator,getComparator,applySortFilter} from "../../utils/utils";
import Notiflix from "notiflix";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
    {id: 'name', label: 'Name', alignRight: false},
    {id: 'company', label: 'Comapnie asociata', alignRight: false},
    {id: 'companyUserAdd', label: 'Comapnie declarata', alignRight: false},
    {id: 'telefon', label: 'Telefon', alignRight: false},
    {id: 'email', label: 'Email', alignRight: false},
    {id: 'dateCreate', label: 'Data creare cont', alignRight: false},
    {id: 'status', label: 'Stare', alignRight: false},
    {id: 'role', label: 'Rol', alignRight: false},
    {id: ''},
];
// ----------------------------------------------------------------------
export default function User() {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [userStatus , setUserStatus] = useState(true);
    //modal
    const [openModalAddUserCompany, setOpenModalAddUserCompany] = useState(false);
    const [openModalAddUserRoles, setOpenModalAddUserRoles] = useState(false);

    const [loadData, setLoadData] = useState(1);
    //grid
    const [users, setUsers] = useState([]);
    const [editRow, setEditRow] = useState({
        lastName: ""
    });

    const getAllUsers = () => {
        Notiflix.Loading.pulse('Se incarca...');
        getCollection("users").then((data) => {
            data = data.filter(item => item.status === userStatus);
            setUsers(data);
            Notiflix.Loading.remove();
        });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = users.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

    const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    useEffect(() => {
        getAllUsers();
    }, [userStatus]);

    useEffect(() => {
        loadData > 1 ? getAllUsers() : "";
    }, [loadData]);
    return (
        <Page title="HNP Romania | Utilizatori ">
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" >
                    <Typography variant="h4" gutterBottom>
                        Utilizatori
                    </Typography>
                </Stack>
                <AddCompanyModal
                    openModal ={openModalAddUserCompany}
                    setOpenModal ={setOpenModalAddUserCompany}
                    editRow ={editRow}
                    setLoadData ={setLoadData}
                    loadData ={loadData}
                />
                <AddUserRoleModal
                    openModal ={openModalAddUserRoles}
                    setOpenModal ={setOpenModalAddUserRoles}
                    editRow ={editRow}
                    loadUserGrid ={getAllUsers}

                />
                <Card>
                    <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                    />
                    <RadioGroup
                        sx={{ml: 2}}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="type"
                        onChange={(event)=>{
                            setUserStatus(!userStatus)
                        }}
                        value={userStatus}
                    >
                        <FormControlLabel value={true} control={<Radio />} label="Utilizatori activi" />
                        <FormControlLabel value={false} control={<Radio />} label="Utilizatori inactivi" />
                    </RadioGroup>
                    <Scrollbar>
                        <TableContainer sx={{minWidth: 800}}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={users.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            const {
                                                id,
                                                name,
                                                displayName,
                                                status,
                                                email,
                                                phone,
                                                companyUserAdd,
                                                companyName,
                                                dateCreate,
                                                position,
                                                role,
                                            } = row;
                                            const isItemSelected = selected.indexOf(name) !== -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            onChange={(event) => handleClick(event, name)}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Avatar alt={displayName} src={''}/>
                                                            <Typography variant="subtitle2" noWrap>
                                                                {displayName}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="left">{companyName}</TableCell>
                                                    <TableCell align="left">{companyUserAdd}</TableCell>
                                                    <TableCell align="left">{phone}</TableCell>
                                                    <TableCell align="left">{email}</TableCell>
                                                    <TableCell align="center">{parseDate(dateCreate)}</TableCell>
                                                    <TableCell align="left">
                                                        <Label
                                                            variant="ghost"
                                                            color={(status === false && 'error') || 'success'}
                                                        >
                                                            {status === true ? "Activ" : "Inactiv"}
                                                        </Label>
                                                    </TableCell>
                                                    <TableCell align="left">{role}</TableCell>
                                                    <TableCell align="right">
                                                        <UserMoreMenu
                                                            row={row}
                                                            setEditRow={setEditRow}
                                                            setOpenModalAddUserCompany={setOpenModalAddUserCompany}
                                                            setOpenModalAddUserRoles={setOpenModalAddUserRoles}
                                                            getAllUsers ={getAllUsers}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{height: 53 * emptyRows}}>
                                            <TableCell colSpan={6}/>
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                                <SearchNotFound searchQuery={filterName}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
