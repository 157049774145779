import {Form, FormikProvider, useFormik} from "formik";
import {Button, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField} from "@mui/material";
import * as Yup from "yup";
import RichText from "../../../components/RichText";
import React from "react";
import Iconify from "../../../components/Iconify";
import MyUpload from "../../../components/Upload/Upload";
import FormControl from "@mui/material/FormControl";
import Switch from '@mui/material/Switch';


export function setInitialData (data) {

    if (data) {
        return {
            title: data.title,
            content: data.content,
            cardImage: data.cover,
            type: data.type,
            active:data.active
        }
    }
    return {
        title: "",
        content: "",
        cardImage: "",
        type: "",
        active:true

    }
}
export const label = { inputProps: { 'aria-label': 'Color switch demo' } };

export const validation = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Titlul este obligatoriu'),
    content: Yup.string().min(2, 'Too Short!').required('Continutul este obligatoriu'),
    cardImage: Yup.string().required('Incarcarea unei fotografii este obligatorie'),
    type: Yup.string().min(2).required('Selectarea tipului este obligatorie')
});
export default function NewPost(props) {
    const {onSubmit,data} = props

    const formik = useFormik({
        initialValues: setInitialData(data),
        validationSchema: validation,
        onSubmit: (formData) => {
            onSubmit(formData);
        },
        enableReinitialize: true,

    });
    const handleSwitchChange = (event) => {
        formik.setFieldValue("active", event.target.checked);
    };

    const {errors, touched, handleSubmit, getFieldProps, setFieldValue} = formik;
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container style={{marginTop: 5,marginLeft:3}} spacing={2}>
                    <Grid item xs={12}>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="type"
                                onChange={(event)=>{
                                    formik.setFieldValue("type", event.target.value);
                                }}
                                value={formik.values.type}
                            >
                                <FormControlLabel value="Oferta" control={<Radio />} label="Oferta" />
                                <FormControlLabel value="Noatate" control={<Radio />} label="Noatate" />
                            </RadioGroup>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Switch {...label} onChange={handleSwitchChange} checked={formik.values.active} name ="active"/>
                            }
                            label="Activeaza/Dezactiveaza"
                        />

                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Titlu noutate"
                            {...getFieldProps('title')}
                            error={Boolean(touched.title && errors.title)}
                            helperText={touched.title && errors.title}
                            value={formik.values.title}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <MyUpload
                            label='Poza prezentare'
                            error={Boolean(touched.cardImage && errors.cardImage)}
                            errorMessage={touched.cardImage && errors.cardImage}
                            value={formik.values.cardImage}
                            onChange={(data) => {
                                formik.setFieldValue("cardImage", data);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Stack>
                            <Stack direction={{xs: 'column', sm: 'row'}}>
                                <RichText
                                    name={"content"}
                                    style={{border: "none"}}
                                    value={formik.values.content}
                                    onChange={(data) => {
                                        setFieldValue('content', data)
                                    }}
                                    error={Boolean(touched.content && errors.content)}
                                    errorMessage={touched.content && errors.content}
                                />
                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            sx={{mb:3}}
                            variant="contained"
                            onClick={() => {
                                formik.submitForm()
                            }}
                        >
                            Salveaza
                        </Button>

                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>

    )
}