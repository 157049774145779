import {analytics, auth, db} from './base';
import {createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword,applyActionCode} from "firebase/auth";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where,
    writeBatch
} from "firebase/firestore";
import {logEvent} from "firebase/analytics";
export const getTotalUsers = async () => {
    try {
        const [usersSnapshot, ordersSnapshot, partnersSnapshot, blogSnapshot] = await Promise.all([
            getDocs(collection(db, 'users')),
            getDocs(collection(db, 'orders')),
            getDocs(collection(db, 'partners')),
            getDocs(collection(db, 'blog'))
        ]);
        return {
            users: usersSnapshot.size,
            orders: ordersSnapshot.size,
            partners: partnersSnapshot.size,
            blog: blogSnapshot.size
        }

    } catch (error) {
        console.error('Error getting total users:', error);
        throw new Error('Internal Server Error');
    }
};
export const registerUser = async (email, password) => {
    const result = await createUserWithEmailAndPassword(auth, email, password);

    const urlRedirect  = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/contulMeu' : 'https://hnp.ro/contulMeu';
    const actionCodeSettings = {
        url: urlRedirect,
    };
    await sendEmailVerification(result.user, actionCodeSettings);
    return result;
};
export const loginUser = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);

};
export const setUserStatus = async (user) =>{
    try {
        const docRef = doc(db, "users", user.id);
        await updateDoc(docRef, {
            status: !user.status
        });
        return true
    } catch {
        return false
    }
}
export const activateEmailAddress = async (uid) => {
    try {
        const washingtonRef = doc(db, "users", uid);
        await updateDoc(washingtonRef, {
            isVerified: true
        });
        return true
    } catch {
        return false
    }
}
export const getProductsPrice = async (ids) => {
    const q = query(collection(db, 'productsPrice2'), where("SUPPLIER_ALT_AID_2", "in", ids));
    let documents = [];
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        documents.push({
            ...{id: doc.id},
            ...doc.data(),
        });
    });
    return documents;
}
export const getCollectionByUser = async (document, userId) => {
    const q = query(collection(db, document), where("customer.uid", "==", userId));
    let documents = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        documents.push({
            ...{id: doc.id},
            ...doc.data(),
        });
    });
    return documents;
}
export const getCollection = async (collectionName) => {
    const querySnapshot = await getDocs(collection(db, collectionName));
    let documents = [];
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        if (collectionName === "users") {
            documents.push(
                {
                    ...{id: doc.id},
                    ...{idAuth: doc.data().userId},
                    ...doc.data(),
                });
        } else {
            documents.push({
                ...{id: doc.id},
                ...doc.data(),
            });
        }
    });
    return documents;
};
export const savePartner = async (partner) => {
    const partnersCollection = 'partners';
    try {
        const docRef = await addDoc(collection(db, partnersCollection), partner);
        console.log("Document written with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
};
export const saveUserDetails = async (id, userDetails) => {
    const userCollection = "users";
    userDetails.userId = id;
    try {
        const docRef = doc(db, userCollection, id);
        await setDoc(docRef, userDetails);
        console.log("Document written with ID: ", docRef.id);        console.log("Document written with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
};
export const getDocumentProperty = async (entity, property, value) => {
    const q = query(collection(db, entity), where(property, "==", value));
    const querySnapshot = await getDocs(q);
    let result = {};
    querySnapshot.forEach((doc) => {
        result = doc.data();
    });
    return result;
};
export const getDocumentsActiveProperty = async (entity, property, value) => {
    const q = query(collection(db, entity), where(property, "==", value), where("active", "==", true));
    const querySnapshot = await getDocs(q);
    let result = [];
    querySnapshot.forEach((doc) => {
        result.push({...doc.data(), ...{uid: doc.id}});
    });
    return result;
};
export const saveUserCompany = async (data) => {
    const batch = writeBatch(db);
    const user = doc(db, 'users', data.user.id);
    batch.update(user, {
        companyId: data.company.id,
        companyName: data.company.name,
        status: true
    });

    await batch.commit();

};
export const setUserRole = async (id,newRole) => {
    const batch = writeBatch(db);
    const user = doc(db, 'users', id);
    batch.update(user, {
        role: newRole.role
    });
    await batch.commit();
}
export const getDocumentById = async (collection, id) => {
    const refDoc = await getRefDocumentById(collection, id);
    return {id: refDoc.id, ...refDoc.data()}
}
const getRefDocumentById = async (collection, id) => {
    const docRef = doc(db, collection, id);
    const lastDocRef = await getDoc(docRef)
    return lastDocRef;
}
export const saveFirebaseDocument = (collectionName, data) => {
    return addDoc(collection(db, collectionName), data);
}
export const saveOrder = async (order) => {
    try {
        logEvent(analytics, 'add_to_cart');
        const collectionName = 'orders';
        return await addDoc(collection(db, collectionName), order);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

export const saveLogProductView = async (product) => {
        logEvent(analytics, 'product_view', {
            items: [
                {
                    item_id: product.id,
                    item_name: product.name,
                }
            ]
        });
}
export const verifyEmailFrontend = async (oobCode: string) => {
try {
        await applyActionCode(auth, oobCode);
        console.log("✅ Email verified successfully!");

        return {success: true, message: "Adresa de email a fost confirmata cu succes."};
    } catch (error) {
        console.error("❌ Error verifying email:", error);
        return {success: false, message: error};
    }
}
export const logPageView = () =>{
    logEvent(analytics, 'page_view', {
        page_title: document.title,
        page_path: window.location.pathname,
    });
}
export const saveProductPRice = async (products) => {
    let i = 0;
    let batch = writeBatch(db)
    let counter = 0;
    let totalCounter = 0;
    const promises = [];

    products.map(async (row) => {
        counter++;
        const product = {
            SUPPLIER_ALT_AID: row[9],
            SUPPLIER_ALT_AID_2: row[2],
            PRICE: row[10]
        }
        const nycRef = doc(db, "productsPrice2", product.SUPPLIER_ALT_AID);
        batch.set(nycRef, product);
        if (counter >= 400) {
            console.log(`Committing batch of ${counter}`);
            promises.push(batch.commit());
            totalCounter += counter;
            counter = 0;
            batch = writeBatch(db)
        }

        await Promise.all(promises);

    })
    return "Done"

};