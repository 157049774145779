import {motion} from 'framer-motion';
import {Link as RouterLink} from 'react-router-dom';
// material
import {styled} from '@mui/material/styles';
import {Box, Button, Typography, Container} from '@mui/material';
// components
import {MotionContainer, varBounceIn} from '../components/animate';
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function Page404() {
    return (
        <RootStyle title="404 Pagina Nu a Fost Găsită | HNP">
            <Container>
                <MotionContainer initial="initial" open>
                    <Box sx={{maxWidth: 480, margin: 'auto', textAlign: 'center'}}>
                        <motion.div variants={varBounceIn}>
                            <Typography variant="h3" paragraph>
                                Ne pare rău, pagina nu a fost găsită! </Typography>
                        </motion.div>
                        <Typography sx={{color: 'text.secondary'}}>
                            Ne pare rău, nu am putut găsi pagina pe care o căutați. Poate ați tastat greșit URL-ul?
                            Asigurați-vă că verificați ortografia.
                        </Typography>

                        <motion.div variants={varBounceIn}>
                            <Box
                                component="img"
                                src="/static/illustrations/illustration_404.svg"
                                sx={{height: 260, mx: 'auto', my: {xs: 5, sm: 10}}}
                            />
                        </motion.div>

                        <Button to="/" size="large" variant="contained" component={RouterLink}>
                            ÎNTOARCE-TE LA PRINCIPALĂ
                        </Button>
                    </Box>
                </MotionContainer>
            </Container>
        </RootStyle>
    );
}
