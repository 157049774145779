import * as React from 'react';
import Grid from '@mui/material/Grid';
import {Box, Typography} from '@mui/material';
import {fCurrency} from "../../../utils/formatNumber";
import {useAuth} from "../../../Auth";

export default function ProductDetails(props) {
    const {data} = props;
/*    const priceSale = data._source.PRICE;
    const priceWithDiscount = data._source.PRICE_DISCOUNT;
    const {currentUser} = useAuth();
    // de luat user daca nu este logat crapa.*/
    return (
        <Grid container spacing={2} sx={{ml:0,pl:0}}>
            <Grid item xs={12} sm={12} md={12} sx={{maxWidth: '100%',p:0}}>
                <Box sx={{maxWidth: '90%'}}>
                    <Typography variant="h4"
                                sx={{maxWidth: '100%'}}
                                dangerouslySetInnerHTML={{__html: data._source.DESCRIPTION_LONG}}
                    />
                </Box>

                <Typography variant="inherit" gutterBottom>
                    {data._source.MANUFACTURER_TYPE_DESCR}
                </Typography>
                <Typography variant="inherit">
                    Marca : {data._source.MANUFACTURER_NAME}
                </Typography>
                <Typography variant="inherit">
                    {data._source.DESCRIPTION_SHORT}
                </Typography>
                <Typography sx={{mt: 2}} variant="inherit" gutterBottom>
                    Cod produs (ISO): <b>{data._source.MANUFACTURER_AID}</b>
                </Typography>
                <Typography variant="inherit" gutterBottom>
                    Cod GTIN / Cod de bare: <b>{data._source.EAN}</b>
                </Typography>
                {/*<Typography variant="inherit" gutterBottom>*/}
                {/*    <a href={data._source.MIMESOURCEDATASHEET} target="_blank" rel="noreferrer"> Data sheet</a>*/}
                {/*</Typography>*/}

{/*                 {currentUser.login && currentUser?.userDetails?.status &&
                     <>
                         {priceSale !== priceWithDiscount && priceSale ? <Typography
                             component="span"
                             variant="body1"
                             sx={{
                                 color: 'text.disabled', textDecoration: 'line-through'
                             }}
                         >
                             {fCurrency(priceSale)}
                         </Typography> : ""}
                         &nbsp;
                         {fCurrency(priceSale !== priceWithDiscount ? priceWithDiscount : priceSale)}

                     </>
                 }*/}

              </Grid>
        </Grid>
    );
}