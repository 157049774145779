import * as actions from './actionTypes.js';
import {handleActions} from 'redux-actions';
const BRANDS_CRUMBS_FIRST = "reseteaza";

export const initState  = [BRANDS_CRUMBS_FIRST]
export const setBreadcrumb = function setBreadcrumb(state = initState, {payload})
{
    return payload;
};

const handlers = {
    [actions.ADD_TO_BREAD_CRUMBS]: setBreadcrumb,
};

export const breadCrumb = handleActions(handlers, initState);