import React from "react";
import readXlsxFile from 'read-excel-file'
import {saveProductPRice} from "../../FireBase/actions";
import Notiflix from "notiflix";

export default function UploadFile() {


    const onChange = (e) => {
        e.preventDefault();
        readXlsxFile(e.target.files[0]).then(async (rows) => {
            Notiflix.Loading.pulse('Uploading data...');
            const data = await saveProductPRice(rows);
            Notiflix.Loading.remove();
            console.log(data);
        })

    };
    return (
        <div>
            <input type="file" onChange={onChange}/>
        </div>
    );
}