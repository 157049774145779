import request from '../../axios/MyAxios';
import {
    createElkFilters,
    createAggregationQuery,
    createAggregationQueryGroup2,
    createAggregationQueryGroup3,
    createAggregationQueryParentChild,
    createQueryProductsByGroup,
    createFilterFavoritesProductsById, createAgregationDiscountGroup
} from './filters';
import Notiflix from 'notiflix';

const PATH = {
    GET_ELK_PRODUCTS: 'public/hnp/elk/data',
    SAVE_PRODUCT: 'save/telegram/channels',
    GET_ELK_PRODUCT_DETAILS: 'public/hnp/elk/getProductByID/',
};
export const getDynamicGroupOptionsByName= async (groupName,parentGroup,childGroup,from,size) => {
    const data = createAggregationQueryParentChild("elk-excel-index-new",groupName,parentGroup,childGroup,from,size);
    return request({
        method: "POST",
        url: PATH.GET_ELK_PRODUCTS,
        data: data,
    });
}
export const getFuzzySearchProducts = async ( index = 'elk-excel-index-new', from, size, searchText) => {
    Notiflix.Loading.init();
    const data = createElkFilters( index, from, size, searchText);
    return request({
        method: "POST",
        url: PATH.GET_ELK_PRODUCTS,
        data: data,
    });
};
export const getFavoriteProducts = async () => {
    const ids =['A1149XPL-15/64IN', 'LNHU160812-F57T WSM35G','P2059905-M20','C6-NTS-SIL27105-22','MD025-12.0E6P150-WJ30RD'];
    const data = createFilterFavoritesProductsById(ids);
    return request({
        method: "POST",
        url: PATH.GET_ELK_PRODUCTS,
        data: data,
    });
};
export const getProductsDetails = async (id) => {
    Notiflix.Loading.init();
    return request({
        method: "GET",
        url: PATH.GET_ELK_PRODUCT_DETAILS + id
    })
};
export const getGroupAggregation = async (groupName) => {
    Notiflix.Loading.init();
    const data = createAggregationQuery("elk-excel-index-new",groupName);
    return request({
        method: "POST",
        url: PATH.GET_ELK_PRODUCTS,
        data: data,
    });
}
export const getGroupAggregation2 = async (groupName) => {
    const data = createAggregationQueryGroup2("elk-excel-index-new",groupName);
    return request({
        method: "POST",
        url: PATH.GET_ELK_PRODUCTS,
        data: data,
    });
}
export const getGroupAggregation3 = async (groupName) => {
    const data = createAggregationQueryGroup3("elk-excel-index-new",groupName);
    return request({
        method: "POST",
        url: PATH.GET_ELK_PRODUCTS,
        data: data,
    });
}
export const getProductsByGroup = async (groupId,groupValue,from,size) => {
    Notiflix.Loading.init();
    const elkFilters = createQueryProductsByGroup( "elk-excel-index-new", from, size,groupId,groupValue);
    return request({
        method: "POST",
        url: PATH.GET_ELK_PRODUCTS,
        data: elkFilters,
    });
}
export const saveProduct = async (data) => {
    Notiflix.Loading.init();
    return request({
        method: "POST",
        url: PATH.SAVE_PRODUCT,
        data: data,
    });
}

export const getDistinctDiscountGroup = async () => {
    const data = createAgregationDiscountGroup("elk-excel-index-new");
    return request({
        method: "POST",
        url: PATH.GET_ELK_PRODUCTS,
        data: data,
    });
}