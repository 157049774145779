export const BUTTONS = [
    {
        title: 'ADMINISTRARE',
        path: '/app',
        login: true,
        roles: ['ROLE_ADMIN' , 'ROLE_VANZARI' , 'ROLE_BACK_OFFICE']
    },
    {
        title: 'UTILIZATORI',
        path: '/user',
        login: true,
        roles: ['ROLE_ADMIN' , 'ROLE_VANZARI' , 'ROLE_BACK_OFFICE']
    },
    {
        title: 'CLIENTI',
        path: '/company',
        login: true,
        roles: ['ROLE_ADMIN' , 'ROLE_VANZARI' , 'ROLE_BACK_OFFICE']
    },
    {
        title: 'PRODUSE',
        path: '/Products',
        login: false,
        roles: ['ROLE_USER']
    },
    {
        title: 'PARTENERI',
        path: '/partners',
        login: false,
        roles: ['ROLE_USER']
    },
    {
        title: 'NOUTAȚI',
        path: '/noutati',
        login: false,
        roles: ['ROLE_USER']
    },
    {
        title: 'CONTUL MEU',
        path: '/contulMeu',
        login: true,
        roles: ['ROLE_USER']
    },
    {
        title: 'CONTACT',
        path: '/contact',
        login: false,
        roles: ['ROLE_USER']
    },

];