import React, {useEffect, useState} from 'react';
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Link} from 'react-router-dom';
import {getAllOffers} from "../../../apiCalls/api/news";


const MyCarousel = () => {

    const [carouselData, setCarouselData] = useState([]);
    const [order, setOrder] = useState('desc')

    useEffect(async () => {
        await getBlogData();
    }, [])

    async function getBlogData() {
      //  const data = await getBlogs(order, 'Oferta');
        const response = await getAllOffers();
        setCarouselData(response.data);

    }

    return (
        <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} style={{lineHeight: '0 !important'}}>
            {carouselData.map((item, index) => (

                <Link to={"/oferta/" + item.id + "-" + item.title.replace(/ /g, "_")}>
                    <div key={index} style={{lineHeight: '0 !important'}}>
                        <img src={item.cover} alt={item.title}/>

                    </div>
                </Link>

            ))}
        </Carousel>
    );
};

export default MyCarousel;