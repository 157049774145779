import {useNavigate, useParams} from 'react-router-dom';
// material
import {Box, Button, Card, Container, Grid, Typography} from '@mui/material';
// components
import Page from '../../components/Page';
import ImageList from './components/ImageList';
//
import useProductDetails from '../ProductDetails/hooks/useProductDetails';
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import ProductDetails from "./components/ProductDetails";
import BasicTable from "../../components/BasicTabel";
import {ProductButtons} from "./components/ProductButtons";
import {useDispatch, useSelector} from "react-redux";
import {addToCartRedux} from "../../store/cart/cartAction";
import NotificationInfo from "../../components/NotificationInfo";
import Footer from "../../layouts/dashboard/Footer/Footer";
import {Helmet} from "react-helmet-async";
import {useAuth} from "../../Auth";


export default function Blog() {
    let {id} = useParams();
    const [seo,setSeo]  = useState({ title: 'Detalii produs', description: 'scule profesionale', keywords: 'freza,burghie,placute-amovibile,scule'});
    const [product, setProductDetails] = useProductDetails(null);
    const cart = useSelector(state => state.cart);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const firstRender = React.useRef(true);
    useEffect(() => {
        setProductDetails(id);
    }, []);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        setProductDetails(id);

    }, [id])
    useEffect(()=>{
        if (product) {
            setSeo(prevSeo => ({
                ...prevSeo, // Menține eventualele valori anterioare
                title: product._source?.MANUFACTURER_TYPE_DESCR,
                description: product._source?.MANUFACTURER_TYPE_DESCR,
                keywords: product._source?.DESCRIPTION_SHORT.replace(/ /g, ',')
            }));

        }

    },[product])
    const addToCart = () => {
        dispatch(addToCartRedux(product, cart));
    }

    const addToWishlist = () => {
        console.log('addToCart');
        console.log(product);
    }


    function checkProductDetails() {
        let productDetails = []
        for(let i=1 ; i <= 45; i++) {
            productDetails.push({
                description: product._source[`FT_DESCR_DIN_${i}`],
                name: product._source[`FT_NAME_DIN_${i}`],
                value:  product._source[`FT_VALUE_DIN_${i}`] + (product._source[`FT_UNIT_DIN_${i}`] ? product._source[`FT_UNIT_DIN_${i}`]  : '')
            })
        }
        return productDetails.filter(item => item.description !== null);
    }

    return (
        <Page data = {seo}>
            {product ?
                <Container maxWidth="xl">
                    <NotificationInfo/>
                    <Card>
                        <Grid sx={{flexGrow: 1}} container>
                            <Grid item xs={12} md={6}>
                                <Button variant="text" onClick={()=>{navigate("/Products")}}> Inapoi</Button>
                                <ImageList data={product._source}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ProductDetails
                                    data={product}
                                />
                                <Grid sx={{ml: 4}}>
                                    <ProductButtons
                                        data={product}
                                        onAddToCart={addToCart}
                                        onAddToWishlist={addToWishlist}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid sx={{m: 2}}>
                            <Grid>
                                <Typography variant="h4" gutterBottom>
                                    Descriere
                                </Typography>
                                <Typography variant="inherit" gutterBottom>
                                    {product._source.UDX_BULLETTEXT_1}
                                </Typography>
                                <Typography variant="inherit" gutterBottom>
                                    {product._source.DESCRIPTION_LONG}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography variant="h4" gutterBottom>
                                    Detalii produse
                                </Typography>
                                <BasicTable
                                    rows={checkProductDetails()}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                    <Box sx ={{mt:2}}>
                        <Footer/>
                    </Box>
                </Container> : <></>
            }
        </Page>
    );
}
