import request from '../../axios/MyAxios';

const PATH = {
    CREATE_DOCUMENT: '/hnp/elk/news',
    UPDATE_DOCUMENT: '/hnp/elk/news/:id',
    GET_ALL_NEWS: '/public/hnp/elk/news',
    GET_NEWS_BY_ID: '/public/hnp/elk/news/:id',
    GET_ALL_OFFERS: '/public/hnp/elk/offers',
};

export const createDocument = async (body) => {
    return request({
        method: "POST",
        url: PATH.CREATE_DOCUMENT,
        data: body,
    });
};

export const updateDocument = async (id, body) => {
    return request({
        method: "PATCH",
        url: PATH.UPDATE_DOCUMENT.replace(':id', id),
        data: body,
    });
};

export const getAllNews = async () => {
    return request({
        method: "GET",
        url: PATH.GET_ALL_NEWS,
    });
};
export const getAllOffers = async () => {
    return request({
        method: "GET",
        url: PATH.GET_ALL_OFFERS,
    });
};

export const getNewsById = async (id) => {
    return request({
        method: "GET",
        url: PATH.GET_NEWS_BY_ID.replace(':id', id),
    });
};