import * as React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

function handleClick(event: React.MouseEvent<Element, MouseEvent>) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

export default function CategoryBreadcrumbs({ categories, onClickCategory }) {
    return (
        <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
                {categories.map((category, index) => (
                    <StyledBreadcrumb
                        key={index}
                        component="a"
                        href="#"
                        label={category}
                        onClick={(event) => {
                            event.preventDefault();
                            onClickCategory(category);
                        }}
                        icon={index === 0 ? <HomeIcon fontSize="small" /> : null}
                        deleteIcon={index === categories.length - 1 ? <ExpandMoreIcon /> : null}
                        onDelete={index === categories.length - 1 ? handleClick : null}
                    />
                ))}
            </Breadcrumbs>
        </div>
    );
}