import Modal from "../../../components/Modal";
import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {Stack} from "@mui/material";
import MyAutoComplete from "../../../components/MyAutoComplete";
import {LoadingButton} from "@mui/lab";
import {useAuth} from "../../../Auth";
import {setUserRole} from "../../../FireBase/actions";
import Notiflix from "notiflix";

export default function AddCompanyModal(props){
    const {openModal,setOpenModal,editRow,loadUserGrid} = props;
    const {currentUser} = useAuth();
    const RegisterSchema = Yup.object().shape({
        role: Yup.object().required('Te rog selecteaza o potiune'),
    });
    const formik = useFormik({
        initialValues: {
            role: '',
        },
        validationSchema: RegisterSchema,
        onSubmit: (formData) => {save(formData)},
    });
    const {errors, touched, handleSubmit} = formik;

    let userRoles = [];

    if (currentUser.userDetails.role !== 'ROLE_ADMIN') {
         userRoles = [
            {role:'ROLE_USER'},
            {role:'ROLE_VANZARI'},
            {role:'ROLE_BACK_OFFICE'}

        ]
    } else {
         userRoles = [
            {role:'ROLE_USER'},
            {role:'ROLE_VANZARI'},
            {role:'ROLE_BACK_OFFICE'},
            {role:'ROLE_ADMIN'},
        ]
    }
    userRoles = userRoles.filter((role) => role.role !== currentUser.userDetails.role);
    const save =  (formData) => {
        console.log("formData",editRow);
            setUserRole(editRow.id,formData.role).then(async ()=>{
                setOpenModal(!openModal);
                await loadUserGrid();
                Notiflix.Notify.success('Rolul a fost schimbat cu succes');
            }).catch((error) =>{
                console.error("Error modifying user role: ",error);
                Notiflix.Notify.failure('A aparut o eroare, va rugam incercati din nou');
            })
        }
    return (
        <Modal
            isOpen={openModal}
            setOpenModal={setOpenModal}
            title={"Atribuie rol pentru utilizatorul: " + editRow.lastName}
        >
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        <MyAutoComplete
                            id="role"
                            name="role"
                            label="Alege rolul utilizatorului"
                            options = {userRoles}
                            getOptionLabel={(option) => option.role}
                            error={Boolean(touched.role && errors.role)}
                            helperText={touched.role && errors.role}
                            formik ={formik}
                        />
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            Salveaza
                        </LoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
        </Modal>
    );
}