import {Link as RouterLink} from 'react-router-dom';
// material
import {styled} from '@mui/material/styles';
import {Box, Card, Container, Link, Typography} from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import {RegisterForm} from '../sections/authentication/register';
import AuthSocial from '../sections/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

const SectionStyle = styled(Card)(({theme}) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({theme}) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
    return (
        <RootStyle title="Register | HNP">
            <AuthLayout>
                Ai deja cont? &nbsp;
                <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
                    Conectează-te
                </Link>
            </AuthLayout>

            <SectionStyle sx={{display: {xs: 'none', md: 'flex'}}}>
                <Typography variant="h3" sx={{px: 5, mt: 10, mb: 5}}>
                    Comandă direct din magazinul online HNP
                </Typography>
                <img alt="register" src="/static/hnp-images/5090115.webp"/>
            </SectionStyle>

            <Container>
                <ContentStyle>
                    <Box sx={{mb: 5}}>
                        <Typography variant="h4" gutterBottom>
                            Crează cont
                        </Typography>
                        <Typography sx={{color: 'text.secondary'}}>
                            Contul tau va fi validat de către un consultant HNP
                        </Typography>
                    </Box>

                  {/*  <AuthSocial/>*/}

                    <RegisterForm/>

                    <Typography variant="body2" align="center" sx={{color: 'text.secondary', mt: 3}}>
                        Prin inregistrare  îți dai acordul cu privire la &nbsp;
                        <Link underline="always" color="textPrimary" href="/static/TERMENI_ȘI_CONDIȚII_HNP.pdf" download  target="_blank" >
                            Termeni și condiții
                        </Link>
                        &nbsp;și&nbsp;
                        <Link underline="always" color="textPrimary" href="/static/TERMENI_ȘI_CONDIȚII_HNP.pdf" download  target="_blank" >
                            Politica de confidențialitate
                        </Link>
                        .
                    </Typography>

                    <Typography
                        variant="subtitle2"
                        sx={{
                            mt: 3,
                            textAlign: 'center',
                            display: {sm: 'none'}
                        }}
                    >
                        Ai deja cont?&nbsp;
                        <Link underline="hover" to="/login" component={RouterLink}>
                            Conecteaza-te
                        </Link>
                    </Typography>
                </ContentStyle>
            </Container>
        </RootStyle>
    );
}
