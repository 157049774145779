import { db } from './base';
import {
    collection,
    doc, getDoc,
    getDocs,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where,
    writeBatch
} from "firebase/firestore";
import Notiflix from "notiflix";

const BLOG = 'blog';

const saveLargeContent = async (id, content) => {
    const CHUNK_SIZE = 1000000; // 1 MB
    const docRef = doc(db, 'largeContents', id);  // Document principal pentru conținutul mare
    console.log(`Saving large content for blog ID: ${id}`);

    const chunks = [];
    for (let i = 0; i < content.length; i += CHUNK_SIZE) {
        chunks.push(content.slice(i, i + CHUNK_SIZE));
    }

    const chunkIds = [];
    let batch = writeBatch(db);
    let operationsCount = 0;

    // Împărțim bucățile în grupuri mai mici
    for (let i = 0; i < chunks.length; i++) {
        const chunkRef = doc(collection(docRef, 'chunks'), String(i));
        batch.set(chunkRef, { content: chunks[i] });
        chunkIds.push(String(i));
        operationsCount++;

        // Dacă am atins limita de 500 de operațiuni, trimitem batch-ul și începem unul nou
        if (operationsCount === 500) {
            await batch.commit();
            console.log(`Batch with 500 chunks committed.`);
            batch = writeBatch(db);  // Creăm un nou batch
            operationsCount = 0;  // Resetăm contorul
        }
    }

    // Trimitem ultimul batch (dacă mai sunt operațiuni necomise)
    if (operationsCount > 0) {
        await batch.commit();
        console.log(`Last batch committed.`);
    }

    // Salvăm referințele la bucăți în documentul principal
    const mainBatch = writeBatch(db);
    mainBatch.set(docRef, { chunkIds });
    await mainBatch.commit();

    console.log('Large content metadata saved.');
    return chunkIds;
};

export const updateBlog = async (id, updatedData) => {
    const blogRef = doc(db, BLOG, id);
    console.log(`Updating blog ID: ${id}`);
    Notiflix.Loading.pulse('Saving blog...');

    if (updatedData.content && updatedData.content.length > 1048487) {
        console.log("Updating large content...");
        const chunkIds = await saveLargeContent(id, updatedData.content);  // Salvăm conținutul mare
        updatedData.hasLargeContent = true;
        updatedData.chunkIds = chunkIds;  // Actualizăm doar referințele
        delete updatedData.content;
    }

    try {
        // Actualizăm documentul
        await updateDoc(blogRef, updatedData);
        console.log(`Blog ${id} updated successfully.`);
    } catch (error) {
        console.error("Error updating blog:", error);
    }
    finally {
        Notiflix.Loading.remove();
    }
};

export const saveBlog = async (blog) => {
    Notiflix.Loading.pulse('Saving blog...');
    const docRef = doc(collection(db, BLOG));  // Creăm ID-ul documentului
    const id = docRef.id;

    let blogData = { ...blog, id };

    console.log(`Generated ID: ${id}`);

    if (blog.content.length > 1048487) {
        console.log("Content is too large, saving separately...");
        const chunkIds = await saveLargeContent(id, blog.content);  // Salvăm conținutul mare
        blogData.hasLargeContent = true;
        blogData.chunkIds = chunkIds;  // Salvăm doar referințele către bucăți
        delete blogData.content;
    }

    try {
        // Salvăm documentul principal
        await setDoc(doc(db, BLOG, id), blogData);
        console.log(`Blog saved successfully with ID: ${id}`);
    } catch (error) {
        console.error("Error saving blog:", error);
    } finally {
        Notiflix.Loading.remove();
    }

    return id;
};

export const getBlogs = async (order, type, isAdmin = false) => {
    let q;

    if (isAdmin) {
        q = query(collection(db, BLOG), where("type", "==", type), orderBy('createdAt', order));
    } else {
        q = query(collection(db, BLOG), where("type", "==", type), where("active", "==", true), orderBy('createdAt', order));
    }

    const querySnapshot = await getDocs(q);
    let result = [];

    for (const docSnapshot of querySnapshot.docs) {
        let blog = docSnapshot.data();
        blog.id = docSnapshot.id;

        // Verificăm dacă documentul are conținut mare și dacă da, îl reconstruiți din bucăți
        if (blog.chunkIds && blog.chunkIds.length > 0) {
            console.log(`Blog with ID: ${blog.id} has large content. Reconstructing content...`);

            // Încărcăm bucățile de conținut
            const chunkRefs = blog.chunkIds.map(chunkId =>
                doc(db, 'largeContents', blog.id, 'chunks', chunkId)
            );

            try {
                const chunkSnapshots = await Promise.all(chunkRefs.map(chunkRef => getDoc(chunkRef)));
                let fullContent = '';
                chunkSnapshots.forEach(snapshot => {
                    if (snapshot.exists()) {
                        fullContent += snapshot.data().content;
                    }
                });
                blog.content = fullContent;  // Reconstruim conținutul mare
            } catch (error) {
                console.error('Error retrieving chunks:', error);
                blog.content = "Error loading content.";
            }
        }

        result.push(blog);
    }

    return result;
};

