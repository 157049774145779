import {combineReducers} from 'redux';
import {user} from './auth/authReducer';
import {cart} from "./cart/cartReducer";
import {breadCrumb} from "./products/productReducer";

const createRootReducer = () => combineReducers({
    user,
    cart,
    breadCrumb
});

export default createRootReducer;